import React, {useContext, useEffect, useState} from "react";
import {Header} from "../components";
import {BackButton} from "../components/buttons";
import styled from "styled-components";
import Select from "react-select";
import {dynoapeAPI} from "../api/dynoape";
import {NavLink, useParams} from "react-router-dom";
import {Alert, Button, Card, Flex, Modal, Spinner} from "rendition";
import Icon from "../lib/icon";
import {
    allTaskResponseOptionsQueryParam,
    isTaskStatusReadOnly, TaskCardSpinner, TaskCardWaitingSpinner
} from "../lib/common";
import {CardRow, InputCard} from "../lib/styled-components";
import Tooltip from "../lib/tooltip";
import dynomiteContext from "../components/dynomite";
import {FeatureToggles} from "../lib/feature-toggles";

const Runners = () => {

    const dynomite = useContext(dynomiteContext);
    const [tasksIdsWithInvalidConfig, setTasksIdsWithInvalidConfig] = useState([]);
    const [isValidating, setIsValidating] = useState(false);

    const {departmentId} = useParams();
    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [departments, setDepartments] = useState([])
    const [runners, setRunners] = useState([]);

    const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null);
    const [eligibleTasksInDepartment, setEligibleTasksInDepartment] = useState([]);
    const [selectedTaskOptions, setSelectedTaskOptions] = useState([]);

    const [showDeleteRunnerModal, setShowDeleteRunnerModal] = useState({id: null, show: false});
    const [showGenerateModal, setShowGenerateModal] = useState({id: null, show: false});

    const [refresh, setRefresh] = useState(0)

    const resetStateValues = () => {
        setSelectedTaskOptions([]);
        setEligibleTasksInDepartment([]);
        setSelectedDepartmentOption(departmentsOptions.filter(d => d.id === departmentId).map(d => ({value: d.id, label: d.name}))[0]);
    }

    /**
     * This method does the following:
     *  - Gets the departments which the user has access to
     *  - Calls refreshRunners() which fetches all runners containing tasks connected to the current department
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        const [departmentData, departmentOptionsData] = await Promise.all([
            await dynoapeAPI.get(`/api/v1/departments`),
            await dynoapeAPI.get(`/api/v1/org/runner/department-options/${departmentId}`)
        ]);

        const deps = departmentData.ownedDepartments.concat(departmentData.externalDepartments);

        let contextDep = deps.filter(d => d.id === departmentId)[0];
        if(contextDep) {
            setSelectedDepartmentOption({value: contextDep.id, label: contextDep.name})
            setDepartmentsOptions(departmentOptionsData);
        } else {
            setDepartmentsOptions([])
        }
        setDepartments(deps)
        await refreshRunners();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshRunners();
            setRefresh(Math.random());
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    /**
     * This method fetches all runners with tasks connected to the current department. The runners
     * are then sorted by registration date and put into state variable runners.
     * @returns {Promise<void>}
     */
    const refreshRunners = async () => {
        let runners = await dynoapeAPI.get(`/api/v1/org/runner/department/${departmentId}`);
        setRunners(runners.sort(function (one, two) {
            return two.registered.localeCompare(one.registered);
        }));
    }

    /**
     * Method for getting all tasks in a specific department which are eligible to be put in a runner.
     * The following rules apply:
     *  - Task needs to be in modifiable state
     *  - Task can not already be connected to an existing runner
     *  - Task can not already be added to selectedTasksOptions
     *  The response is then put into state variable eligibleTasksInDepartment
     * @returns {Promise<void>}
     */
    const getEligibleTasksInDepartment = async () => {
        if(selectedDepartmentOption) {
            let tasks = await dynoapeAPI.get(`/api/v1/department/${selectedDepartmentOption.value}/tasks`);
            setEligibleTasksInDepartment(
                tasks.elements.some(t => selectedTaskOptions.map(op => op.value).includes(t.id))
                    ? []
                    : tasks.elements
                        .filter(t => !isTaskStatusReadOnly(t.status))
                        .filter(t => !t.runnerId)
                        .filter(t => !selectedTaskOptions.map(op => op.value).includes(t.id))
            );
        }
    }

    /**
     * Method for creating a new runner
     * @returns {Promise<void>}
     */
    const post = async () => {
        if(!selectedTaskOptions || selectedTaskOptions.length === 0) {
            return;
        }
        let resp = await dynoapeAPI.post(`/api/v1/org/runner`, {ids: selectedTaskOptions.map(st => st.value)});
        if(resp) {
            resetStateValues();
            await refreshRunners();
        }
    }

    /**
     * Method for deleting a runner
     * @param id
     * @returns {Promise<void>}
     */
    const del = async (id) => {
        await dynoapeAPI.delete(`/api/v1/org/runner/${id}`);
        resetStateValues();
        await refreshRunners();
    }

    /**
     * Method for generating a runner. The method requests the validate method and if the config in all connected
     * tasks are valid, generate is triggered and method returns true.
     * @param id
     * @returns {Promise<boolean>}
     */
    const generate = async (id) => {
        setTasksIdsWithInvalidConfig([]);
        let valid = await validate(id);
        if(valid) {
            let resp = await dynoapeAPI.get(`/api/v1/org/runner/${id}/execute`);
            if(resp) {
                await refreshRunners();
                return true;
            }
        }
        return false;
    }

    /**
     * Method for validating all task configs in a runner.
     * The tasks inside the runner does not include the config, so each task needs to be fetched using
     * the get task endpoint. Each task is then validated. For those tasks that are invalid, the id will be put
     * into state variable tasksWithInvalidConfig
     * @param runnerId
     * @returns {Promise<boolean>}
     */
    const validate = async (runnerId) => {
        setIsValidating(true);
        const runner = runners.find(tl => tl.id === runnerId);
        const invalid = (
            await Promise.all(
                runner.tasks.map(async t => {
                    try {
                        const task = await dynoapeAPI.get(`/api/v1/department/${t.departmentId}/task/${t.id}${allTaskResponseOptionsQueryParam}`);
                        dynomite.dynomite.parseConfig(JSON.stringify(task.config));
                        return null;
                    } catch (err) {
                        console.error(err.payload);
                        const errs = err.payload.filter(e =>
                            e.tag.includes("Warning::")
                        );
                        return errs.length > 0 ? t.id : null;
                    }
                })))
            .filter(Boolean);
        setTasksIdsWithInvalidConfig(invalid);
        setIsValidating(false);
        return invalid.length === 0;
    }

    /**
     * UseEffect executed once during start up. Calls getData() which fetches departments and runners
     */
    useEffect(() => {
        if(FeatureToggles.FEATURE_TOGGLE_RUNNER_MODULE.isEnabled()) {
            getData();
        }
    }, [])

    /**
     * UseEffect executed every time a department is selected from a dropdown menu or a task is added to an unsaved runner.
     * Calls getEligibleTasksInDepartment() which gets all the tasks in the department
     * that are eligible to be added to a runner
     */
    useEffect(() => {
        getEligibleTasksInDepartment();
    }, [selectedDepartmentOption, selectedTaskOptions]);

    function errorElement (msg) {
        return (<div className="content"><p>{msg}.Ta kontakt med eier av avdelingen eller <a href="mailto: support@dynamon.no">support@dynamon.no</a></p></div>)
    }

    if(!FeatureToggles.FEATURE_TOGGLE_RUNNER_MODULE.isEnabled()) {
        return errorElement("Denne modulen er ikke tilgjengelig");
    }

    return (
        <div>
            <Header title={"Kjøreplaner"}
                    showLicenseGenerationsInfo={true} refresh={refresh}
                    withTooltip
                    tooltipId="runner-header"
                    tooltipContent="Her kan du se og sette opp kjøreplaner for bemanningsplaner som skal kjøres">
                <BackButton />
            </Header>
            <div className="content">
                <Card>
                    <h4>Kjøreplaner</h4>
                    <Info>
                        På denne siden kan man legge en eller flere eksisterende bemanningsplaner i én kjøreplan.
                        Dersom flere bemanningsplaner velges, vil disse kjøres parallelt med avhengigheter til hverandre.
                    </Info>
                    <br/>
                    <Info>
                        Før du starter, vennligst se følgende retningslinjer:
                        <ul>
                            <li>Det er kun mulig å velge bemanningsplaner fra avdelinger som bruker er eier av.</li>
                            <li>Én enkelt bemanningsplan, kan kun forekomme i én kjøreplan.</li>
                            <li>Hver kjøreplan må inneholde en bemanningsplan fra avdelingen du står i.</li>
                            <li>
                                Bemanningsplaner fra forskjellige avdelinger kan kun forekomme i en kjøreplan
                                dersom det allerede er ansatte som delt mellom de aktuelle avdelingene.
                            </li>
                        </ul>
                    </Info>

                    <h4>Opprett kjøreplan</h4>
                    <Select
                        placeholder="Velg avdeling"
                        styles={{ container: base => ({ ...base, width: "400px", marginTop: "10px"}) }}
                        value={selectedDepartmentOption}
                        options={departmentsOptions.map(d => ({value: d.id, label: d.name}))}
                        onChange={(e) => {setSelectedDepartmentOption(e)}}
                        data-for="select-department"
                        data-tip="Velg en avdeling før du velger bemanningsplan"
                    >
                    </Select>
                    <Tooltip id="select-department" />

                    {eligibleTasksInDepartment?.length > 0 &&
                        <>
                            <Select
                                placeholder="Velg bemanningsplan"
                                styles={{ container: base => ({ ...base, width: "400px", marginTop: "10px"}) }}
                                options={eligibleTasksInDepartment.map(t => ({value: t.id, label: t.name, departmentId: t.departmentId}))}
                                onChange={(e) => {
                                    const newSelectedTaskOptions = [...selectedTaskOptions, e];
                                    setSelectedTaskOptions(newSelectedTaskOptions);
                                    setEligibleTasksInDepartment(eligibleTasksInDepartment.filter(t => !newSelectedTaskOptions.map(op => op.value).includes(t.id)));
                                }}
                                value={null}
                                data-for="select-task"
                                data-tip="Velg en bemanningsplan som du ønsker å legge til i kjøreplanen"
                            ></Select>
                            <Tooltip id="select-task" />
                        </>
                    }
                    {selectedDepartmentOption && eligibleTasksInDepartment?.length === 0 &&
                        <Info style={{marginTop: "10px"}}>Ingen bemanningsplaner å velge</Info>
                    }
                    {selectedTaskOptions?.length > 0 &&
                        <Flex>
                            {selectedTaskOptions.map(st => (
                                    <Flex
                                        style={{cursor: "pointer"}}
                                        onClick={() => setSelectedTaskOptions(selectedTaskOptions.filter(t => t.value !== st.value))}
                                        mt={3} mr={3} mb={3} key={["flex", "st", st.id].join("-")}
                                    >
                                        <Info>{st.label} ({departmentsOptions.filter(d => d.id === st.departmentId)[0]?.name}) </Info>
                                        <Icon name="remove"/>
                                    </Flex>
                                )
                            )}
                        </Flex>
                    }
                    {selectedTaskOptions?.length > 0 && selectedTaskOptions.map(st => st.departmentId).includes(departmentId) &&
                        <Button style={{width: "120px"}} onClick={post} success mt={3}>Lagre</Button>
                    }
                    <h4>Eksisterende kjøreplaner</h4>
                    <div>
                        {runners.map(tl => (
                            <CardRow key={["card-row", tl.id].join("-")}>
                                <InputCard style={{marginTop: "20px"}} key={["input-card", "info", tl.id].join("-")}>
                                    <ul>
                                        {tl.tasks
                                            .map(t =>
                                                <li key={["div", t.id].join("-")}>
                                                    <Info>{t.name} ({departments.filter(d => d.id === t.departmentId)[0].name})</Info>
                                                </li>)
                                        }
                                    </ul>
                                </InputCard>
                                <InputCard style={{marginTop: "20px"}} key={["input-card", "button", tl.id].join("-")}>
                                    <Flex style={{height: "100%"}}>
                                        {(!isTaskStatusReadOnly(tl.status) || tl.status === "FINISHED") &&
                                            <>
                                                {tl.status !== "FINISHED" &&
                                                    <>
                                                        <Button
                                                            style={{height: "100%"}}
                                                            primary
                                                            onClick={() => setShowGenerateModal({id: tl.id, show: true})}
                                                            data-for="start-generating"
                                                            data-tip="Trykk på denne for å kjøre alle bemanningsplanene i kjøreplane parallelt">
                                                            Start generering
                                                        </Button>
                                                        <Tooltip id="start-generating" />
                                                    </>
                                                }
                                                <Button
                                                    ml={40}
                                                    style={{height: "100%"}}
                                                    danger
                                                    onClick={() => setShowDeleteRunnerModal({id: tl.id, show: true})}
                                                    data-for="delete-runner"
                                                    data-tip="Trykk på denne for å slette kjøreplanen">
                                                    Slett kjøreplan
                                                </Button>
                                                <Tooltip id="delete-runner" />
                                            </>
                                        }
                                        {tl.status === "FINISHED" &&
                                            <div style={{marginLeft: 40, fontSize: "40px"}}>
                                                <Alert plaintext success></Alert>
                                                <Info>Ferdig</Info>
                                            </div>
                                        }
                                        {
                                            ['ERROR_GENERIC'].includes(tl.status)
                                            && <div style={{marginLeft: 40, fontSize: "40px"}}>
                                                <Alert plaintext danger></Alert>
                                                <Info>Feil</Info>
                                            </div>
                                        }
                                        {
                                            ['ERROR_IN_CONFIG'].includes(tl.status)
                                            && <div style={{marginLeft: 40, fontSize: "40px"}}>
                                                <Alert plaintext warning></Alert>
                                                <Info>Ugyldig</Info>
                                            </div>
                                        }
                                        {
                                            ['FINISHED_WITH_NO_RESULTS'].includes(tl.status)
                                            && <div style={{marginLeft: 40, fontSize: "40px"}}>
                                                <Alert plaintext danger></Alert>
                                                <Info>Ingen resultat</Info>
                                            </div>
                                        }
                                        {tl.status === 'QUEUED' && <TaskCardWaitingSpinner label="Venter på at generering skal starte"></TaskCardWaitingSpinner>}
                                        {tl.status === 'STARTING' && <TaskCardWaitingSpinner label="Starter generering"></TaskCardWaitingSpinner>}
                                        {tl.status === 'PROCESSING' && <TaskCardSpinner label="Generering pågår"></TaskCardSpinner>}
                                    </Flex>
                                </InputCard>
                            </CardRow>
                        ))}
                    </div>
                </Card>

            </div>
            {showDeleteRunnerModal.show &&
                <Modal
                    action="OK"
                    done={() => {
                        del(showDeleteRunnerModal.id);
                        setShowDeleteRunnerModal({id: null, show: false});
                    }}
                    cancel={() => {
                        setShowDeleteRunnerModal({id: null, show: false});
                    }}
                    cancelButtonProps={{
                        style: { display: "none" }
                    }}
                    secondaryButtonProps={{
                        children: 'Avbryt',
                        onClick: () => setShowDeleteRunnerModal({id: null, show: false})
                    }}
                ><h3><Alert plaintext danger style={{ whiteSpace: "nowrap"}}>Fjerne</Alert></h3>
                    <p style={{fontSize: "17px"}}>Er du sikker på at du ønsker å slette denne kjøreplanen?</p>
                </Modal>

            }
            {showGenerateModal.show &&
                <Modal
                    done={async () => {
                        if(await generate(showGenerateModal.id)) {
                            setShowGenerateModal({id: null, show: false});
                        }
                    }}
                    cancel={() => {
                        setShowGenerateModal({id: null, show: false});
                        setTasksIdsWithInvalidConfig([]);
                    }}
                    cancelButtonProps={{
                        style: { display: "none" }
                    }}
                    secondaryButtonProps={{
                        children: 'Avbryt',
                        onClick: () => {
                            setShowGenerateModal({id: null, show: false});
                            setTasksIdsWithInvalidConfig([]);
                        }
                    }}
                    action="Start generering"
                >
                    <h3><Alert plaintext success style={{ whiteSpace: "nowrap"}}>Generer</Alert></h3>
                    <p style={{ fontSize: "20px" }}>Er du sikker på at du vil starte parallel generering av bemanningsplanene i denne kjøreplanen? </p>
                    {isValidating && <Spinner label="Validerer. Vennligst vent!"/>}
                    {tasksIdsWithInvalidConfig.length > 0 && (() => {
                        const runner = runners.find(tl => tl.id === showGenerateModal.id);
                        if (runner) {
                            return (
                                <>
                                    <Info>Følgende bemanningsplaner har valideringsfeil. Trykk for å gå til en bemanningsplan: </Info>
                                    <ul>
                                        {tasksIdsWithInvalidConfig.map(inv => (() => {
                                                const task = runner.tasks.find(t => t.id === inv);
                                                return (
                                                    <li key={["invalid", "li", inv].join("-")}>
                                                        <Info>
                                                            <NavLink className="nav-link" to={`/avdelinger/${task.departmentId}/bemanningsplaner/${task.id}`}>
                                                                {task.name}
                                                            </NavLink>
                                                        </Info>
                                                    </li>
                                                );
                                            }
                                        )())}
                                    </ul>
                                </>
                            );
                        }
                        return null;
                    })()}
                </Modal>

            }
        </div>
    )
}
export default Runners
const Info = styled.div`
  font-family: Montserrat Alternates;
  font-size: 16px;
`;