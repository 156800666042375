import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Button, Spinner, Modal, Checkbox, Flex} from "rendition";
import { CombinedCheckbox } from "./checkboxes";
import {
  dynoapeAPI,
  getSortedCompetences,
  getSortedPositions,
  getSortedShiftTypes,
  rawDynoapeAPI
} from "../api/dynoape";
import {useParams, useHistory, NavLink} from "react-router-dom";
import { RouterPrompt } from "../lib/confirm/router-prompt";
import { useForm, Controller } from "react-hook-form";
import {
  CardRow,
  InputDivider,
  InputWithValidation,
  InputCard,
  EmployeeNumberInput,
  SolidInput,
  TextArea, TransparentCard
} from "../lib/styled-components";
import { Collapsible} from "grommet";
import ErrorMessage from './error-message';
import {
  emplShiftMinMaxSeqErrorMessage,
  maxNrOfDecimalRegex,
  validCharactersPattern,
  emplWorksOnlyWeekendsErrorMessage, sortShiftTypesByDaySegment
} from "../lib/common";
import Tooltip from "../lib/tooltip";
import { MultiSelectPicker } from "./multi-select-picker";
import {FeatureToggles} from "../lib/feature-toggles";
import {daySegmentMaxOptions, shiftOptions} from "../lib/options";

const EmployeePage = ({ employee, existingEmployeeNames, isNew }) => {
  const { watch, register, handleSubmit, control, formState: { errors, dirtyFields }, setValue } = useForm({
    defaultValues: {
      enabled: employee.enabled,
      worksOnlyWeekend: employee.worksOnlyWeekend,
      comment: employee.comment,
      traits: Object.entries(employee.traitNames)?.map(t => ({ label: t[1], value: t[0] })),
      shiftTypes: employee.restShiftTypes?.map(sh => ({label: sh.code, value: sh.id})),
      maxDayShifts: employee.maxDayShifts !== undefined && employee.maxDayShifts > -1
          ? employee.maxDayShifts
          : "",
      maxEveningShifts: employee.maxEveningShifts !== undefined && employee.maxEveningShifts > -1
        ? employee.maxEveningShifts
        : "",
      maxLongShifts: employee.maxLongShifts !== undefined && employee.maxLongShifts > -1
        ? employee.maxLongShifts
        : "",
      maxNightShifts: employee.maxNightShifts !== undefined && employee.maxNightShifts > -1
        ? employee.maxNightShifts
        : "",
      weekendShiftMaxSequence: employee.weekendShiftMaxSequence !== undefined && employee.weekendShiftMaxSequence > -1
        ? employee.weekendShiftMaxSequence
        : -1,
      dayShiftsMaxPerWeek: employee.dayShiftsMaxPerWeek !== undefined && employee.dayShiftsMaxPerWeek > -1
          ? employee.dayShiftsMaxPerWeek
          : "",
      eveningShiftsMaxPerWeek: employee.eveningShiftsMaxPerWeek !== undefined && employee.eveningShiftsMaxPerWeek > -1
          ? employee.eveningShiftsMaxPerWeek
          : "",
      longShiftsMaxPerWeek: employee.longShiftsMaxPerWeek !== undefined && employee.longShiftsMaxPerWeek > -1
          ? employee.longShiftsMaxPerWeek
          : "",
      nightShiftsMaxPerWeek: employee.nightShiftsMaxPerWeek !== undefined && employee.nightShiftsMaxPerWeek > -1
          ? employee.nightShiftsMaxPerWeek
          : "",
      maxShifts: employee.maxShifts !== undefined && employee.maxShifts > -1
          ? employee.maxShifts
          : "",
      maxNightShiftWeekends: employee.maxNightShiftWeekends !== undefined && employee.maxNightShiftWeekends > -1
          ? employee.maxNightShiftWeekends
          : "",
      sundaysMaxSequence: employee.sundaysMaxSequence
    }
  });

  const history = useHistory();
  const isDirty = !!Object.keys(dirtyFields).length;
  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [positions, setPositions] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [advShiftsSettingsOpen, setAdvShiftsSettingsOpen] = useState(false);
  const [editMode, setEditMode] = useState(isNew);
  const [orgEmployee, setOrgEmployee] = useState(undefined)
  const [authorizedToSeeOrgEmployee, setAuthorizedToSeeOrgEmployee] = useState(true);

  const onSubmit = data => {
    setSubmitted(true);
    putEmployee(data);
  };

  const { departmentId } = useParams();
  const [shiftMaxSequenceValidationMsg, setShiftMaxSequenceValidationMsg] = useState("");
  const [shiftWorksOnlyWeekendValidationMsg, setWorksOnlyWeekendsValiationMsg] = useState("");

  const getData = async () => {
    const [
      positionsData,
      competencesData,
      shiftTypesData
    ] = await Promise.all([
      getSortedPositions(departmentId),
      getSortedCompetences(departmentId),
      getSortedShiftTypes(departmentId)
    ]);
    if (positionsData.length === 0)
      setShowModal(true);

    setPositions(positionsData);
    setCompetences(competencesData);
    setShiftTypes(shiftTypesData);

    if(employee.orgEmployeeId) {
      const orgEmplResp = await rawDynoapeAPI.get(`/api/v1/org/org-employee/${employee.orgEmployeeId}`);
      if(orgEmplResp && orgEmplResp.status === 200) {
        setAuthorizedToSeeOrgEmployee(true);
        let orgEmpl = orgEmplResp.data.body;
        orgEmpl.employees = orgEmpl.employees.filter(e => e.id !== employee.id)
        setOrgEmployee(orgEmpl);
      } else if(orgEmplResp?.response?.status === 401) {
        setAuthorizedToSeeOrgEmployee(false)
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  let positionOptions = (positions) => {
    return positions?.map((position, index) => <option key={`pos${index}`} value={position.id}>{position.name}</option>);
  };

  const handleFreeNrInputs = async (elId, value) => {
    let val = Math.floor(parseInt(value));
    if (isNaN(val))
      return setValue(elId, "");
    if (val > 999)
      val = 999;
    if (val < 0)
      val = "";
    return setValue(elId, val);
  }

  const putEmployee = async (data) => {
    const employeeData = {
      ...data,
      "traits": data.traits?.map(t => t.value),
      "shiftTypes": data.shiftTypes?.map(sh => sh.value),
      "name": data.name.trim(),
      "vacancyRate": data.vacancyRate.replace(/,/g, '.'),
      "enabled": data.enabled ? true : false,
      "nrOfQuickReturnsPerWeekOnWeekdays": data.nrOfQuickReturnsPerWeekOnWeekdays,
      "nrOfQuickReturnsPerWeekOnWeekend": data.nrOfQuickReturnsPerWeekOnWeekend,
      "comment": data.comment ? data.comment.trim() : "",
      "worksOnlyWeekend": data.worksOnlyWeekend ? true : false,
      "maxDayShifts": data.maxDayShifts && data.maxDayShifts > -1 ? data.maxDayShifts : null,
      "maxEveningShifts": data.maxEveningShifts && data.maxEveningShifts > -1 ? data.maxEveningShifts : null,
      "maxLongShifts": data.maxLongShifts && data.maxLongShifts > -1 ? data.maxLongShifts : null,
      "maxNightShifts": data.maxNightShifts && data.maxNightShifts > -1 ? data.maxNightShifts : null,
      "dayShiftsMaxPerWeek": data.dayShiftsMaxPerWeek && data.dayShiftsMaxPerWeek > -1 ? data.dayShiftsMaxPerWeek : null,
      "eveningShiftsMaxPerWeek": data.eveningShiftsMaxPerWeek && data.eveningShiftsMaxPerWeek > -1 ? data.eveningShiftsMaxPerWeek : null,
      "longShiftsMaxPerWeek": data.longShiftsMaxPerWeek && data.longShiftsMaxPerWeek > -1 ? data.longShiftsMaxPerWeek : null,
      "nightShiftsMaxPerWeek": data.nightShiftsMaxPerWeek && data.nightShiftsMaxPerWeek > -1 ? data.nightShiftsMaxPerWeek : null,
      "maxShifts": data.maxShifts && data.maxShifts > -1 ? data.maxShifts : null,
      "maxNightShiftWeekends": data.maxNightShiftWeekends && data.maxNightShiftWeekends > -1 ? data.maxNightShiftWeekends : null,
      "priority": employee.priority,
      "redDaysMaxSequence": data.redDaysMaxSequence ? data.redDaysMaxSequence : employee.redDaysMaxSequence,
      "shiftMinSequence": data.shiftMinSequence ? data.shiftMinSequence : employee.shiftMinSequence,
      "movableHolidaysMaxPerTurnus": data.movableHolidaysMaxPerTurnus ? data.movableHolidaysMaxPerTurnus : employee.movableHolidaysMaxPerTurnus,
    };

    const returnData = isNew
        ? await dynoapeAPI.post(`/api/v1/department/${departmentId}/employee`, employeeData)
        : await dynoapeAPI.put(`/api/v1/department/${departmentId}/employee/${employee.id}`, employeeData);

    if (returnData) {
      history.goBack();
    } else {
      setSubmitted(false);
    }
  }

  var maxConsecutiveShiftsOptions = [];
  for (const val of shiftOptions()) {
    maxConsecutiveShiftsOptions.push(<option key={`opt-${val}`} value={val}>{val}</option>);
  }

  var daySegmentMaxSeqOptions = [];
  for(const val of daySegmentMaxOptions) {
    daySegmentMaxSeqOptions.push(<option key={`opt-${val}`} value={val}>{val}</option>);
  }

  var maxConsecutiveWeekendShiftsOptions = [];
  for (const val of shiftOptions()) {
    if(val <= 2) continue;
    maxConsecutiveWeekendShiftsOptions.push(<option key={`opt-${val}`} value={val}>{val}</option>);
  }

  const checkShiftSequenceData = async () => {
    let msg = emplShiftMinMaxSeqErrorMessage(
        watch('enabled'),
        parseInt(watch('shiftMinSequence')) ? parseInt(watch('shiftMinSequence')) : employee.shiftMinSequence,
        parseInt(watch('shiftMaxSequence')),
        parseInt(watch('dayShiftMinSequence')),
        parseInt(watch('dayShiftMaxSequence')),
        parseInt(watch('eveningShiftMinSequence')),
        parseInt(watch('eveningShiftMaxSequence')),
        parseInt(watch('longShiftMinSequence')),
        parseInt(watch('longShiftMaxSequence')),
        parseInt(watch('nightShiftMinSequence')),
        parseInt(watch('nightShiftMaxSequence')),
        watch('weekendShiftMaxSequence'),
        watch('shiftTypes') ? watch('shiftTypes').map(op => op.label) : [],
        shiftTypes);
    setShiftMaxSequenceValidationMsg(msg);
    return msg === "";
  }

  const orgEmployeeToggleEnabled = () => {
    return FeatureToggles.FEATURE_TOGGLE_ORG_EMPLOYEE_MODULE.isEnabled();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading ? <Spinner show /> :
        <div>
          <RouterPrompt
            when={!submitted && isDirty && editMode}
            title="Er du sikker på at du vil forlate siden?"
            onOK={() => true}
            onCancel={() => false}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <CardRow>
              <TransparentCard>
                {!isNew && <Checkbox
                    toggle
                    reverse
                    ml="400px"
                    mt="10px"
                    label="Rediger ansatt"
                    checked={editMode}
                    onChange={() => setEditMode(!editMode)}
                />}
              </TransparentCard>
              <TransparentCard>
                <div
                    style={{
                      display: "flex",
                    }}
                >
                  <Flex>
                    <Button
                        primary
                        type="submit"
                        style={{display: editMode ? "block" : "none"}}
                        ml="425px"
                        mt="10px"
                        data-for="employee-save"
                        data-tip="Du må lagre dine endringer før du forlater denne siden"
                    >
                      Lagre</Button>
                    <Tooltip id="employee-save" />
                  </Flex>
                </div >
              </TransparentCard>
            </CardRow>
            <CardRow>
              <InputCard>
                <h3>Personalia</h3>
                <InputDivider>
                  <label htmlFor={"name"}>Navn</label>
                  <InputWithValidation>
                    <SolidInput
                      name="name"
                      readOnly={!editMode}
                      placeholder="Navn"
                      defaultValue={employee.name}
                      style={{ width: "300px", backgroundColor: editMode ? "white": "WhiteSmoke" }}
                      {...register("name", {
                        required: true,
                        pattern: validCharactersPattern(),
                        maxLength: 40,
                        validate: () => {
                          return existingEmployeeNames.filter(function (e) {
                            let name = e.name ? e.name.toLowerCase() : "";
                            let newName = watch("name") ? watch("name").toLowerCase() : "";
                            return e.id !== employee.id && name.trim() === newName.trim();
                          }).length === 0
                        }
                      })}
                      data-for="employee-name"
                      data-tip="Her skrives navnet på den ansatte (unngå bruk av for- og etternavn pga personvern)"
                    />
                    <Tooltip id="employee-name" />
                    {ErrorMessage(errors.name, 'required', "Et navn er påkrevd, skriv inn et navn på den ansatte")}
                    {ErrorMessage(errors.name, 'validate', "Det finnes allerede en ansatt med det navnet")}
                    {ErrorMessage(errors.name, 'pattern', "Du har brukt noen ugyldige tegn")}
                    {ErrorMessage(errors.name, 'maxLength', "Navn på ansatte kan ikke være lenger enn 40 tegn")}
                  </InputWithValidation>
                </InputDivider>
                <InputDivider>
                  <label htmlFor="position">Stilling</label>
                  <Select
                    style={{width: "inherit", backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    id="position"
                    defaultValue={employee.position ? employee.position : ""}
                    {...register("position")}
                    data-for="employee-position"
                    data-tip="Velg hvilken stilling den ansatte har i avdelingen (rullegardinsmeny)"
                  >
                    {positionOptions(positions)}
                  </Select>

                  <Tooltip id="employee-position" />
                </InputDivider>
                <InputDivider>
                  <label htmlFor={"vacancyRate"}>Stillingsprosent</label>
                  <InputWithValidation>
                    <EmployeeNumberInput
                      id="vacancyRate"
                      defaultValue={employee.vacancyRate}
                      step="any"
                      readOnly={!editMode}
                      placeholder="Stillingsprosent"
                      style={{ width: "162px", backgroundColor: editMode ? "white": "WhiteSmoke"  }}
                      {...register("vacancyRate", { required: true, min: 1.0, max: 100, pattern: maxNrOfDecimalRegex(2) })}
                      data-for="employee-vacancy"
                      data-tip="Her skrives stillingsprosenten som den ansatte er ansatt i"
                    />

                    <Tooltip id="employee-vacancy" />
                  </InputWithValidation>
                </InputDivider>
                {ErrorMessage(errors.vacancyRate, 'required', 'Stillingsprosent er påkrevd')}
                {ErrorMessage(errors.vacancyRate, 'min', 'Stillingsprosent kan ikke være mindre enn 1%')}
                {ErrorMessage(errors.vacancyRate, 'max', 'Stillingsprosent kan ikke være større enn 100%')}
                {ErrorMessage(errors.vacancyRate, 'pattern', 'Maks to desimaler tillatt')}
                {!isNew && orgEmployeeToggleEnabled() &&
                    <div>
                      <h3 style={{color: "#398089"}}>
                        Arbeid i andre avdelinger
                      </h3>
                      {authorizedToSeeOrgEmployee && orgEmployee &&
                          <ul>
                            {orgEmployee.employees.map(e =>
                            <li style={{fontFamily: "Montserrat Alternates", fontSize: "16px"}} key={["empl","pers",e.name].join("-")}>
                              {e.name}, {e.positionName}, {e.vacancyRate}%, {e.departmentName}
                            </li>
                            )}
                          </ul>
                      }
                      {!authorizedToSeeOrgEmployee &&
                        <div style={{fontFamily: "Montserrat Alternates"}}>Du har ikke rettigheter til å se disse opplysningene</div>
                      }
                      {authorizedToSeeOrgEmployee && !orgEmployee &&
                          <div style={{fontFamily: "Montserrat Alternates"}}>Denne ansatte jobber ikke i andre avdelinger</div>
                      }
                      {authorizedToSeeOrgEmployee &&
                          <NavLink className="nav-link" to={`/avdelinger/${departmentId}/ansatte/${employee.id}/knytt`}>
                            <a className="arrow-link">Se eller endre<span className="arrow"></span></a>
                          </NavLink>
                      }
                    </div>
                }
              </InputCard>

              <InputCard>
                <h3>Spesialkompetanse</h3>

                <label>Spesialkompetanse</label>

                <Tooltip id="employee-traits" />
                <div data-for="employee-traits" data-tip="Tildel spesialkompetanse til den ansatte fra nedtrekks gardinen. Dette betyr at den ansatte kan prioriteres til vakter der denne spesialkompetansen er et krav">
                  <Controller
                    name="traits"
                    control={control}
                    defaultValue={Object.entries(employee.traitNames)?.map(t => ({ label: t[1], value: t[0] }))}
                    {...register("traits")}
                    render={({ field }) =>
                      <MultiSelectPicker
                        inputRef={field.ref}
                        disabled={!editMode}
                        defaultValue={Object.entries(employee.traitNames).sort(function (a, b) {
                          return a[1].localeCompare(b[1], 'no', { sensitivity: 'base' });
                        })?.map(t => ({ label: t[1], value: t[0] }))}
                        defaultOptions={competences?.map(p => ({ label: p.name, value: p.id }))}
                        onChange={(e) => field.onChange(e?.map(val => val.value))}
                        placeholderPostfix="spesialkompetanser"
                        {...field}
                      />
                    } />
                </div>
                <label>Vaktkoder</label>

                <Tooltip id="employee-shiftTypes" />
                <div data-for="employee-shiftTypes" data-tip="Tildel vaktkode til den ansatte fra nedtrekks gardinen. Dette betyr at den ansatte kan gå denne vakten.">
                  <Controller
                      name="shiftTypes"
                      control={control}
                      defaultValue={sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => ({label: sh.code, value: sh.id}))}
                      {...register("shiftTypes")}
                      render={({ field }) =>
                          <MultiSelectPicker
                              inputRef={field.ref}
                              disabled={!editMode}
                              defaultValue={sortShiftTypesByDaySegment(employee.restShiftTypes)?.map(sh => ({label: sh.code, value: sh.id}))}
                              defaultOptions={sortShiftTypesByDaySegment(shiftTypes)?.map(sh => ({ label: sh.code, value: sh.id }))}
                              onChange={(e) => field.onChange(e?.map(val => val.value))}
                              placeholderPostfix="vaktkoder"
                              {...field}
                          />
                      } />
                </div>
              </InputCard>
            </CardRow>

            <CardRow>
              <InputCard>
                <h3>Vakter</h3>

                {ErrorMessage(errors.shiftMaxSequence, 'validate',
                    shiftMaxSequenceValidationMsg)}

                <InputDivider>
                  <label htmlFor={"shiftMaxSequence"}>Maks. antall vakter på rad</label>
                    <Select
                      id="shiftMaxSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.shiftMaxSequence}
                      {...register("shiftMaxSequence", {
                        validate: () => checkShiftSequenceData()
                      })}
                      data-for="employee-shiftMaxSequence"
                      data-tip="Her fyller du inn maks antall vakter på rad den ansatte kan ha, uavhengig av vakt type (rullegardinsmeny)"
                    >
                      {maxConsecutiveShiftsOptions}
                    </Select>

                    <Tooltip id="employee-shiftMaxSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"weekendShiftMaxSequence"}>Maks. antall vakter på rad rundt helg</label>
                    <Select
                        defaultValue={employee.weekendShiftMaxSequence}
                        style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                        id="weekendShiftMaxSequence"
                        {...register("weekendShiftMaxSequence", {
                          validate: () => {
                            return checkShiftSequenceData();
                          }
                        })}
                        data-for="employee-weekendShiftMaxSequence"
                        data-tip="Her fyller du inn maks antall vakter på rad rundt helg den ansatte kan ha, uavhengig av vakt type (rullegardinsmeny)"
                    >
                      <option selected="selected" value="-1">-</option>
                      {maxConsecutiveWeekendShiftsOptions}
                    </Select>

                    <Tooltip id="employee-weekendShiftMaxSequence" />
                </InputDivider>

                <hr/>

                <InputDivider>
                  <label htmlFor={"dayShiftMinSequence"}>Min. dagvakter på rad</label>
                  <Select
                      id="dayShiftMinSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.dayShiftMinSequence}
                      {...register("dayShiftMinSequence")}
                      data-for="employee-dayShiftMinSequence"
                      data-tip="Her fyller du inn minimum antall dagvakter på rad den ansatte skal ha (rullegardinsmeny)."
                  >
                    <option value="0">0</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Select>

                  <Tooltip id="employee-dayShiftMinSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"dayShiftMaxSequence"}>Maks. dagvakter på rad</label>
                  <Select
                      id="dayShiftMaxSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.dayShiftMaxSequence}
                      {...register("dayShiftMaxSequence", {
                        validate: () => {
                          let min = watch('dayShiftMinSequence');
                          let max = watch('dayShiftMaxSequence');
                          return (min <= max);
                        }
                      })}
                      data-for="employee-dayShiftMaxSequence"
                      data-tip="Her fyller du inn maks antall dagvakter på rad den ansatte kan ha (rullegardinsmeny)."
                  >
                    {daySegmentMaxSeqOptions}
                  </Select>

                  <Tooltip id="employee-dayShiftMaxSequence" />
                </InputDivider>
                {ErrorMessage(errors.dayShiftMaxSequence, 'validate',
                    'Minimum dagvakter på rad kan ikke være høyere enn maks. dagvakter på rad')}
                <hr/>

                <InputDivider>
                  <label htmlFor={"eveningShiftMinSequence"}>Min. kveldsvakter på rad</label>
                  <Select
                      id="eveningShiftMinSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.eveningShiftMinSequence}
                      {...register("eveningShiftMinSequence")}
                      data-for="employee-eveningShiftMinSequence"
                      data-tip="Her fyller du inn minimum antall kveldsvakter på rad den ansatte skal ha (rullegardinsmeny)."
                  >
                    <option value="0">0</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Select>

                  <Tooltip id="employee-eveningShiftMinSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"eveningShiftMaxSequence"}>Maks. kveldsvakter på rad</label>
                  <Select
                    id="eveningShiftMaxSequence"
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    defaultValue={employee.eveningShiftMaxSequence}
                    {...register("eveningShiftMaxSequence", {
                      validate: () => {
                        let min = watch('eveningShiftMinSequence');
                        let max = watch('eveningShiftMaxSequence');
                        return (min <= max);
                      }
                    })}
                    data-for="employee-eveningShiftMaxSequence"
                    data-tip="Her fyller du inn maks antall kveldsvakter på rad den ansatte kan ha (rullegardinsmeny)."
                  >
                    {daySegmentMaxSeqOptions}
                  </Select>

                  <Tooltip id="employee-eveningShiftMaxSequence" />
                </InputDivider>
                {ErrorMessage(errors.eveningShiftMaxSequence, 'validate',
                    'Minimum kveldsvakter på rad kan ikke være høyere enn maks kveldsvakter på rad')}
                <hr/>

                <InputDivider>
                  <label htmlFor={"nightShiftMinSequence"}>Min. nattevakter på rad</label>
                  <Select
                    id="nightShiftMinSequence"
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    defaultValue={employee.nightShiftMinSequence}
                    {...register("nightShiftMinSequence")}
                    data-for="employee-nightShiftMinSequence"
                    data-tip="Her fyller du inn minimum antall nattevakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny)."
                  >
                    <option value="0">0</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Select>

                  <Tooltip id="employee-nightShiftMinSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"nightShiftMaxSequence"}>Maks. nattevakter på rad</label>
                  <Select
                    id="nightShiftMaxSequence"
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    defaultValue={employee.nightShiftMaxSequence}
                    {...register("nightShiftMaxSequence", {
                      validate: () => {
                        let min = watch('nightShiftMinSequence');
                        let max = watch('nightShiftMaxSequence');
                        return (min <= max);
                      }
                    })}
                    data-for="employee-nightShiftMaxSequence"
                    data-tip="Her fyller du inn maks antall nattevakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny)."
                  >
                    {maxConsecutiveShiftsOptions}
                  </Select>

                  <Tooltip id="employee-nightShiftMaxSequence" />
                </InputDivider>

                {ErrorMessage(errors.nightShiftMaxSequence, 'validate',
                  'Minimum nattevakter på rad kan ikke være høyere en maks nattevakter på rad')}
                <hr/>

                <InputDivider>
                  <label htmlFor={"longShiftMinSequence"}>Min. langvakter/mellomvakter på rad</label>
                  <Select
                      id="longShiftMinSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.longShiftMinSequence}
                      {...register("longShiftMinSequence")}
                      data-for="employee-longShiftMinSequence"
                      data-tip="Her fyller du inn minimum antall langvakter/mellomvakter på rad den ansatte kan ha etter hverandre (rullegardinsmeny). "
                  >
                    <option value="0">0</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Select>

                  <Tooltip id="employee-longShiftMinSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"longShiftMaxSequence"}>Maks. langvakter/mellomvakter på rad</label>
                    <Select
                      id="longShiftMaxSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      name="longShiftMaxSequence"
                      defaultValue={employee.longShiftMaxSequence}
                      {...register("longShiftMaxSequence", {
                        validate: () => {
                          let minSeq = watch('longShiftMinSequence');
                          let maxSeq = watch('longShiftMaxSequence');
                          let enabled = watch('enabled');
                          return !enabled || (minSeq <= maxSeq);
                        }
                      })}
                      data-for="employee-longShiftMaxSequence"
                      data-tip="Fyll inn maks antall langvakter/mellomvakter på rad den ansatte kan ha (rullegardinsmeny)."
                    >
                      {daySegmentMaxSeqOptions}
                    </Select>

                    <Tooltip id="employee-longShiftMaxSequence" />
                </InputDivider>
                {ErrorMessage(errors.longShiftMaxSequence, 'validate',
                    'Ansatte som skal arbeide langvakter må ha "Maks langvakter/mellomvakter på rad" til en verdi høyere enn "Min. langvakter/mellomvakter på rad og "0"')}

                <h3 style={{cursor: "pointer"}} onClick={() => setAdvShiftsSettingsOpen(!advShiftsSettingsOpen)}>{advShiftsSettingsOpen ? "Skjul" : "Vis"} avanserte innstillinger</h3>
                <Collapsible open={advShiftsSettingsOpen}>
                  <div style={{rowGap: "2em", display: "flex", flexDirection: "column"}}>
                    <InputDivider>
                      <label htmlFor={"shiftMinSequence"}>Min. antall vakter på rad</label>
                      <Select
                          id="shiftMinSequence"
                          style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                          defaultValue={employee.shiftMinSequence}
                          {...register("shiftMinSequence")}
                          data-for="employee-shiftMinSequence"
                          data-tip="Her fyller du inn min antall vakter på rad den ansatte må ha, uavhengig av vakt type (rullegardinsmeny)"
                      >
                        <option value="0">0</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Select>

                      <Tooltip id="employee-shiftMinSequence" />
                    </InputDivider>

                    <hr/>

                    <InputDivider>
                      <label htmlFor="maxShifts">Maks. antall vakter i turnus</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxShifts"
                            defaultValue={employee.maxShifts}
                            step="any"
                            {...register("maxShifts", { required: false })}
                            data-for="employee-maxShifts"
                            data-tip="Her fyller du inn maks antall vakter den ansatte skal kunne ha i HELE turnusperioden"
                            onBlur={e => {
                              return handleFreeNrInputs("maxShifts", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxShifts" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="dayShiftsMaxPerWeek">Maks. dagvakter per uke</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="dayShiftsMaxPerWeek"
                            defaultValue={employee.dayShiftsMaxPerWeek}
                            step="any"
                            {...register("dayShiftsMaxPerWeek", { required: false })}
                            data-for="employee-dayShiftsMaxPerWeek"
                            data-tip="Her fyller du inn maks antall dagvakter den ansatte skal kunne gå per uke"
                            onBlur={e => {
                              return handleFreeNrInputs("dayShiftsMaxPerWeek", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-dayShiftsMaxPerWeek" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="maxDayShifts">Maks. dagvakter i turnus</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxDayShifts"
                            defaultValue={employee.maxDayShifts}
                            step="any"
                            {...register("maxDayShifts", { required: false })}
                            data-for="employee-maxDayShifts"
                            data-tip="Her fyller du inn maks antall dagvakter den ansatte skal kunne gå i HELE turnusperioden"
                            onBlur={e => {
                              return handleFreeNrInputs("maxDayShifts", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxDayShifts" />
                      </InputWithValidation>
                    </InputDivider>

                    <hr/>

                    <InputDivider>
                      <label htmlFor="eveningShiftsMaxPerWeek">Maks. kveldsvakter per uke</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="eveningShiftsMaxPerWeek"
                            defaultValue={employee.eveningShiftsMaxPerWeek}
                            step="any"
                            {...register("eveningShiftsMaxPerWeek")}
                            data-for="employee-eveningShiftsMaxPerWeek"
                            data-tip="Her fyller du inn maks antall kveldsvakter den ansatte skal kunne gå per uke"
                            onBlur={e => {
                              return handleFreeNrInputs("eveningShiftsMaxPerWeek", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-eveningShiftsMaxPerWeek" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="maxEveningShifts">Maks. kveldsvakter i turnus</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxEveningShifts"
                            defaultValue={employee.maxEveningShifts}
                            step="any"
                            {...register("maxEveningShifts")}
                            data-for="employee-maxEveningShifts"
                            data-tip="Her fyller du inn maks antall kveldsvakter den ansatte skal kunne gå i HELE turnusperioden"
                            onBlur={e => {
                              return handleFreeNrInputs("maxEveningShifts", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxEveningShifts" />
                      </InputWithValidation>
                    </InputDivider>

                    <hr/>

                    <InputDivider>
                      <label htmlFor="nightShiftsMaxPerWeek">Maks. nattevakter per uke</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="nightShiftsMaxPerWeek"
                            defaultValue={employee.nightShiftsMaxPerWeek}
                            step="any"
                            {...register("nightShiftsMaxPerWeek")}
                            data-for="employee-nightShiftsMaxPerWeek"
                            data-tip="Her fyller du inn maks antall nattevakter den ansatte skal kunne per uke"
                            onBlur={e => {
                              return handleFreeNrInputs("nightShiftsMaxPerWeek", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-nightShiftsMaxPerWeek" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="maxNightShifts">Maks. nattevakter i turnus</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxNightShifts"
                            defaultValue={employee.maxNightShifts}
                            step="any"
                            {...register("maxNightShifts")}
                            data-for="employee-maxNightShifts"
                            data-tip="Her fyller du inn maks antall nattevakter den ansatte skal kunne gå i HELE turnusperioden"
                            onBlur={e => {
                              return handleFreeNrInputs("maxNightShifts", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxNightShiftWeekends" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="maxNightShiftWeekends">Maks. nattevakthelger</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxNightShiftWeekends"
                            defaultValue={employee.maxNightShiftWeekends}
                            step="any"
                            {...register("maxNightShiftWeekends")}
                            data-for="employee-maxNightShiftWeekends"
                            data-tip="Her fyller du inn maks antall nattevakthelger den ansatte skal kunne jobbe"
                            onBlur={e => {
                              return handleFreeNrInputs("maxNightShiftWeekends", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxNightShiftWeekends" />
                      </InputWithValidation>
                    </InputDivider>

                    <hr/>

                    <InputDivider>
                      <label htmlFor="longShiftsMaxPerWeek">Maks. langvakter/mellomvakter per uke</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="longShiftsMaxPerWeek"
                            defaultValue={employee.longShiftsMaxPerWeek}
                            step="any"
                            {...register("longShiftsMaxPerWeek")}
                            data-for="employee-longShiftsMaxPerWeek"
                            data-tip="Her fyller du inn maks antall langvakter/mellomvakter den ansatte skal kunne gå per uke"
                            onBlur={e => {
                              return handleFreeNrInputs("longShiftsMaxPerWeek", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxLongShifts" />
                      </InputWithValidation>
                    </InputDivider>
                    <InputDivider>
                      <label htmlFor="maxLongShifts">Maks. langvakter/mellomvakter i turnus</label>
                      <InputWithValidation>
                        <MaxShiftInput
                            readOnly={!editMode}
                            style={{backgroundColor: editMode ? "white": "Whitesmoke"}}
                            id="maxLongShifts"
                            defaultValue={employee.maxLongShifts}
                            step="any"
                            {...register("maxLongShifts")}
                            data-for="employee-maxLongShifts"
                            data-tip="Her fyller du inn maks antall langvakter/mellomvakter den ansatte skal kunne gå i HELE turnusperioden"
                            onBlur={e => {
                              return handleFreeNrInputs("maxLongShifts", e.target.value);
                            }}
                        />

                        <Tooltip id="employee-maxLongShifts" />
                      </InputWithValidation>
                    </InputDivider>
                  </div>
                </Collapsible>
              </InputCard>

              <InputCard>
                <h3>Tilgjengelig</h3>
                {ErrorMessage(errors.worksOnlyWeekend, 'validate',
                  shiftWorksOnlyWeekendValidationMsg)}
                <InputDivider>
                  <label htmlFor="enabled">Den ansatte er tilgjengelig for vakter</label>

                  <Tooltip id="employee-enabled" />
                  <div
                    data-for="employee-enabled"
                    data-tip="Dersom den ansatte skal tas med i generering av turnus må du markere at den ansatte er tilgjengelig for vakter">
                    <Controller
                      name="enabled"
                      control={control}
                      defaultValue={employee.enabled}
                      render={({ field }) => <CombinedCheckbox disabled={!editMode} defaultChecked={employee.enabled} {...field} />}
                    />
                  </div>
                </InputDivider>
                <InputDivider>
                  <label htmlFor="worksOnlyWeekend">Den ansatte jobber kun helg</label>

                  <Tooltip id="employee-worksOnlyWeekend" />
                  <div
                    data-for="employee-worksOnlyWeekend"
                    data-tip="Dersom den ansatte kun skal arbeide helgevakter i turnus hakes det av her">
                    <Controller
                      name="worksOnlyWeekend"
                      {...register("worksOnlyWeekend", {
                        validate: () => {
                          let msg = emplWorksOnlyWeekendsErrorMessage(
                            parseInt(watch('shiftMaxSequence')),
                            parseInt(watch('eveningShiftMaxSequence')),
                            parseInt(watch('longShiftMaxSequence')),
                            parseInt(watch('nightShiftMinSequence')),
                            parseInt(watch('nightShiftMaxSequence')),
                            watch('enabled'),
                            watch('worksOnlyWeekend'));
                          setWorksOnlyWeekendsValiationMsg(msg);
                          return msg === "";
                        }
                      })}
                      control={control}
                      defaultValue={employee.worksOnlyWeekend}
                      render={({ field }) => <CombinedCheckbox disabled={!editMode} defaultChecked={employee.worksOnlyWeekend} {...field} />}
                    />
                  </div>
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"sundaysMaxSequence"}>Maks. antall søndager på rad</label>
                  <Select
                      id="sundaysMaxSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.sundaysMaxSequence}
                      {...register("sundaysMaxSequence")}
                      data-for="employee-sundaysMaxSequence"
                      data-tip="Velg maks. antall søndager på rad den ansatte kan jobbe"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Select>
                  <Tooltip id="employee-sundaysMaxSequence" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"nrOfQuickReturnsPerWeekOnWeekend"}>Maks. antall sein-tidlig vakter i helgen</label>
                  <Select
                    id="nrOfQuickReturnsPerWeekOnWeekend"
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    defaultValue={employee.nrOfQuickReturnsPerWeekOnWeekend}
                    {...register("nrOfQuickReturnsPerWeekOnWeekend")}
                    data-for="employee-nrOfQuickReturnsPerWeekOnWeekend"
                    data-tip="Her kan du markere hvor mange sein-tidlig vakter den ansatte kan ta i en arbeidshelg helg (helg = seinvakt fredag til søndag)"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </Select>

                  <Tooltip id="employee-nrOfQuickReturnsPerWeekOnWeekend" />
                </InputDivider>

                <InputDivider>
                  <label htmlFor={"nrOfQuickReturnsPerWeekOnWeekdays"}>Maks. antall sein-tidlig vakter på ukedager</label>
                  <Select
                    id="nrOfQuickReturnsPerWeekOnWeekdays"
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                    defaultValue={employee.nrOfQuickReturnsPerWeekOnWeekdays}
                    {...register("nrOfQuickReturnsPerWeekOnWeekdays")}
                    data-for="employee-nrOfQuickReturnsPerWeekOnWeekdays"
                    data-tip="Her kan du markere hvor mange sein-tidlig vakter den ansatte kan ta på ukedager"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </Select>

                  <Tooltip id="employee-nrOfQuickReturnsPerWeekOnWeekdays" />
                </InputDivider>
                <InputDivider>
                  <label htmlFor={"redDaysMaxSequence"}>Maks. antall røde dager på rad</label>
                  <Select
                      id="redDaysMaxSequence"
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      defaultValue={employee.redDaysMaxSequence}
                      {...register("redDaysMaxSequence")}
                      data-for="employee-redDaysMaxSequence"
                      data-tip="Her kan du markere hvor mange røde dager den ansatte kan ha på rad"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Select>

                  <Tooltip id="employee-redDaysMaxSequence" />
                </InputDivider>
                <InputDivider>
                  <label htmlFor={"movableHolidaysMaxPerTurnus"}>Maks. antall bevegelige høytidsdager</label>
                  <Select
                      style={{backgroundColor: editMode ? "white": "WhiteSmoke", pointerEvents: editMode ? "auto": "none" }}
                      id="movableHolidaysMaxPerTurnus"
                      defaultValue={employee.movableHolidaysMaxPerTurnus}
                      {...register("movableHolidaysMaxPerTurnus")}
                      data-for="employee-movableHolidaysMaxPerTurnus"
                      data-tip="Her kan du sette maks antall bevegelige høytidsdager den ansatte ønsker å arbeide i turnusperioden"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Select>

                  <Tooltip id="employee-movableHolidaysMaxPerTurnus" />
                </InputDivider>
                <h3 style={{ margin: "0px" }}>Kommentar</h3>
                <span style={{ color: "rgb(191, 61, 43)", margin: "-20px 0px -20px 0px" }}>Ikke skriv sensitive personopplysninger her</span>
                <Tooltip id="employee-comment" />
                <TextArea
                    name="comment"
                    placeholder="Kommentar"
                    disabled={!editMode}
                    style={{backgroundColor: editMode ? "white": "WhiteSmoke"}}
                    defaultValue={employee.comment}
                    {...register("comment", {
                      pattern: validCharactersPattern()
                    })}
                    rows={4}
                    data-for="employee-comment"
                    data-tip="Dersom du har egne kommentarer på ansattkortet kan du notere dette her. Eksempel - Jobbet 24-26 desember 2022, eller ferieuke 25,26 og 27 2022"
                />
                {ErrorMessage(errors.comment, 'pattern', "Du har brukt noen ugyldige tegn")}

              </InputCard>
            </CardRow>
          </div>
          {showModal && (
            <Modal
              title="Ingen stillinger opprettet"
              cancel={() => {

              }}
              cancelButtonProps={{
                style: { display: "none" }
              }}
              done={() => {
                setSubmitted(true);
                setTimeout(function () {
                  history.push(`/avdelinger/${departmentId}/avdelingsoppsett`);
                }, 200);
              }}
              secondaryButtonProps={{
                children: 'Nei, gå tilbake',
                onClick: () => {
                  setSubmitted(true);
                  history.goBack();
                }
              }}
              action="Ja, gå til Avdelingsoppsett"
            >
              <p>Denne avdelingen har ingen stillinger opprettet, vil du opprette disse nå?</p>
            </Modal>
          )}
        </div>
      }
    </form >
  )
}

export default EmployeePage;

const Select = styled.select`
  // border: 1px solid #E5E7f0;
  width: 75px;
`;

const MaxShiftInput = styled(EmployeeNumberInput)`
  width: 70px;
  border: 1px solid #777;
  border-radius: 0px;
  text-align: center;
  padding: 0px;
`;