import React from "react";
import ErrorMessage from "../error-message";
import ErrorMessagePopover from "../error-message-popover";
import { InputWithValidation, NumberInput } from "../../lib/styled-components";
import {isTaskReadOnly, noDecimalRegex, shortenStr} from "../../lib/common";
import { Button } from "rendition";
import styled from "styled-components";

const PositionInputs = ({ positions, register, InputError, day, shift, errors, getOneCoverErrorMessage,
                          maxNrOfEmpls, watch, highlightedInputs, deletePatch, task, checkLengthAndAdjust }) => {
  const validateTimeCover = function (watchedInputs) {
    return maxNrOfEmpls() >= watchedInputs.filter(wi => wi).reduce((partialSum, a) => partialSum + a, 0);
  }
  let errorMessages = [];
  let watchedInputs = [];
  let positionInputs = positions?.map((position) => {
    errorMessages.push(ErrorMessage(InputError(day, errors, shift)?.positionRequirements?.[position.id], 'validate', 'Bemanning i kjernetid kan ikke overskride maksimum antall ansatte'));
    watchedInputs.push(parseInt(watch(`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`)));
    return <InputDivider key={`config.weeklyCoverDemands.${day}.${shift}.${position.id}`}>
      <InputWithValidation width="190px">
        <p><label htmlFor={`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`}>{shortenStr(positions.find(p => p.id === position.id)?.name, 20)}</label></p>
        <NumberInput
          id={`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`}
          key={`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`}
          placeholder=""
          type="number"
          onChange={checkLengthAndAdjust(`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`, 999)}
          width="80px"
          defaultValue={/*(shiftData && shiftData.positionRequirements[position.id] !== null) ? shiftData.positionRequirements[position.id] :*/ "0"}
          className={highlightedInputs.includes(`/${shift}/positionRequirements/${position.id}`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
          {...register(`config.weeklyCoverDemands.${day}.${shift}.positionRequirements.${position.id}`, {
            required: true, valueAsNumber: true, min: 0, pattern: noDecimalRegex(), validate: () => {
              return validateTimeCover(watchedInputs);
            }
          })}
        />
        {ErrorMessagePopover(InputError(day, errors, shift)?.positionRequirements?.[position.id], 'required', `Antall ${position.name} må ha en tallverdi`)}
        {ErrorMessagePopover(InputError(day, errors, shift)?.positionRequirements?.[position.id], 'min', `Antall ${position.name} kan ikke være negative tall`)}
        {ErrorMessagePopover(InputError(day, errors, shift)?.positionRequirements?.[position.id], 'pattern', 'Desimaltall er ikke tillatt')}
        <div style={{ display: !highlightedInputs.includes(`/${shift}/positionRequirements/${position.id}`) ? "none" : "", position: "absolute", float: "right", width: "120px", paddingTop: "2px", pointerEvents: "none" }}>
          <Button className="deletePatchButton" style={{ float: "right", pointerEvents: "all" }} ml="auto" plain onClick={() => deletePatch(`${day}.${shift}.positionRequirements.${position.id}`)}><img src="/assets/icons/roll.png" /></Button>
        </div>
      </InputWithValidation>
    </InputDivider>
  });

  return <div>
    <InputRow>
      {positionInputs}
    </InputRow>
    {getOneCoverErrorMessage(errorMessages)}
  </div>
}

export default PositionInputs;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 2em;
`;

const InputDivider = styled.div`
  width: 190px;
  margin-bottom: 10px;
`;