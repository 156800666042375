import React, {useEffect, useState} from "react";
import {Alert, Button, Checkbox, Modal, Spinner} from "rendition";
import {Header, TaskCard} from "../components";
import {dynoapeAPI} from "../api/dynoape";
import {useHistory, useParams} from "react-router-dom";
import {BackButton} from "../components/buttons";
import {defaultConfigObj} from "../lib/defaults";
import {validCharactersRegex} from "../lib/common";
import Tooltip from "../lib/tooltip";
import {SolidInput} from "../lib/styled-components";
import styled from "styled-components";

const Tasks = () => {
  const [refresh, setRefresh] = useState(0);
  const [showAddTaskModal, setShowTaskModal] = useState({ "show": false, "copyFromId": "", spin: false, excludeCustomBP: false });
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState({"show": false, "taskId": "", "name": ""});
  const [showInvalidNameError, setShowInvalidNameError] = useState({ "show": false, "msg": "" });
  const [showConfirmGenerateModal, setShowConfirmGenerateModal] = useState({ "show": false, "generateId": "" });
  const [showErrorModal, setShowErrorModal] = useState({taskId: "", show: false});
  const [showStoppedModal, setShowStoppedModal] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { departmentId } = useParams();
  const [taskName, setNameInput] = useState("");
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const history = useHistory();

  const [pagination, setPagination] = useState({page: 0, size: 5})

  const closeModal = () => {
    setShowInvalidNameError({ "show": false, "msg": "" });
    setNameInput("");
    setShowTaskModal({ "show": false, "copyFromId": "", spin: false, excludeCustomBP: false });
  };

  const getData = async () => {
    const [
      tasksData,
      employeesData
    ] = await Promise.all([
      dynoapeAPI.get(`/api/v1/department/${departmentId}/tasks?page=${pagination.page}&size=${pagination.size}&includeResultInfo=true`),
      dynoapeAPI.get(`/api/v1/department/${departmentId}/employees`),
    ]);

    setTasks(tasksData);
    setEmployees(employeesData);
    setIsLoading(false);
  };

  const getDepartmentTask = async (taskId) => {
    return await dynoapeAPI.get(`/api/v1/department/${departmentId}/task/${taskId}`);
  };

  const generatePlan = async (taskId) => {
    await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, [{ "op": "replace", "path": "/status", "value": "QUEUED" }]);
    getData();
  };

  const registerTask = async () => {
    defaultConfigObj.name = taskName.trim();
    const response = await dynoapeAPI.post(`/api/v1/department/${departmentId}/task`, defaultConfigObj);
    if(response) {
      setIsLoading(true);
      history.push(`/avdelinger/${departmentId}/bemanningsplaner/${response.id}`);
    }
  };

  const deleteTask = async (taskId) => {
    setIsLoading(true)
    await dynoapeAPI.delete(`/api/v1/department/${departmentId}/task/${taskId}`);
    await getData();
    setIsLoading(false);
  }

  const copyTask = async (taskId) => {
    let task = await getDepartmentTask(taskId);
    ["id", "registered", "updated", "departmentId", "started", "ended", "message", "secondsLimit", "runnerId", "result"]
        .forEach(field => delete task[field]
    );
    ["positions", "traits", "shiftTypes", "fullVacancyRateHours", "datePatterns", "holidays",
      "employees", "f3WorkHours", "f3MinVacancyRate", "f1FreeHours", "name", "version"]
        .forEach(field => delete task['config'][field]);

    task.name = taskName;
    task.status = "PREPARING";
    if(showAddTaskModal.excludeCustomBP) task.config.dailyCoverPatches = {};
    const response = await dynoapeAPI.post(`/api/v1/department/${departmentId}/task`, task);
    if(response) {
      setIsLoading(true);
      history.push(`/avdelinger/${departmentId}/bemanningsplaner/${response.id}`);
    }
  };

  const showStopped = () => {
    setShowStoppedModal(true)
  }

  const showError = async (task) => {
    setShowErrorModal({taskId: task.id, show: true});
  }

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
      setRefresh(Math.random());
    }, 5000);

    return () => clearInterval(interval);
  }, [pagination]);

  function TaskList(props) {
    const tasks = props.tasks;
    const listTasks = tasks?.map((task) => <TaskCard key={task.id} task={task} showStopped={props.showStopped} showError={props.showError} departmentId={departmentId}
                                                     copyTask={copyTask} setShowTaskModal={setShowTaskModal} setShowConfirmGenerateModal={setShowConfirmGenerateModal} setShowDeleteTaskModal={setShowDeleteTaskModal}/>); return (
      <div>{listTasks}</div>);
  }

  return (
    <div>
      <Header title={"Bemanningsplaner"} showLicenseGenerationsInfo={true} refresh={refresh}
        withTooltip
        tooltipId="tasks-header"
        tooltipContent="Her finner du liste over alle bemanningsplaner du har laget">
        <BackButton />
      </Header>
      <div className="content">
        <div>
          <div
            style={{
              display: "flex",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}
              data-for="tasks"
              data-tip="Her ser du en liste over de Bemanningsplaner som allerede er laget">
              Liste over bemanningsplaner</h3>
            <Tooltip id="tasks" />
            <Button
              icon={<p>+</p>}
              style={{
                marginRight: "10px",
                marginTop: "20px",
              }}
              primary
              onClick={() => { employees.length > 0 ? setShowTaskModal({ "show": true, "copyFromId": "", spin: false, excludeCustomBP: false }) : setShowEmployeeModal(true) }}
              ml={"auto"}
              data-for="add-task"
              data-tip="Klikk her for å legge til en ny bemanningsplan">Legg til ny bemanningsplan</Button>
            <Tooltip id="add-task" />
          </div>

          {isLoading ? <Spinner emphasized show /> : !tasks?.elements.length ?
            <div style={{ background: "white", padding: "10px 20px 20px 10px" }}><h4>Ingen bemanningsplaner funnet</h4></div>
            : <TaskList tasks={tasks.elements} generatePlan={generatePlan} copyTask={copyTask} showError={showError} showStopped={showStopped} />
          }
          {!isLoading && pagination.size < tasks?.totalSize &&
              <div style={{textAlign: "center"}}>
              <Button
                  data-tip="Trykk her hvis du ønsker å last inn flere bemanningsplaner"
                  data-for="showMoreBps"
                  style={{marginBottom: "40px"}}
                  underline
                  primary
                  onClick={() => {
                    setIsLoading(true)
                    setPagination({...pagination, size: pagination.size + 5})
                  }}><BigInfo>Last inn flere...</BigInfo>
              </Button>
              </div>
          }
        </div>
      </div>
      {showDeleteTaskModal.show
          && <Modal
              action="OK"
              done={() => {
                deleteTask(showDeleteTaskModal.taskId)
                setShowDeleteTaskModal({"show": false, "taskId": "", "name": ""})
              }}
              cancel={() => {
                setShowDeleteTaskModal({"show": false, "taskId": "", "name": ""})
              }}
              cancelButtonProps={{
                style: { display: "none" }
              }}
              secondaryButtonProps={{
                children: 'Avbryt',
                onClick: () => setShowDeleteTaskModal({"show": false, "taskId": "", "name": ""})
              }}
          ><h3><Alert plaintext danger style={{ whiteSpace: "nowrap" }}>Vil du slette?</Alert></h3><p>Er du sikker på at du vil slette {showDeleteTaskModal.name}? Alle turnusplaner som er generert vil også bli slettet.</p>
          </Modal>
      }
      {
        showAddTaskModal.show && (
          <Modal
            title={showAddTaskModal.copyFromId === "" ? "Legg til ny bemanningsplan" : "Bestem navn på den kopierte bemanningsplanen"}
            cancel={() => {
              closeModal();
            }}
            cancelButtonProps={{
              children: 'Avbryt'
            }}
            done={() => {
              if (taskName.trim().length <= 0) {
                setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på bemanningsplanen" });
                return;
              }
              if (showInvalidNameError.show) {
                return;
              }
              setShowTaskModal({...showAddTaskModal, spin: true})

              if (showAddTaskModal.copyFromId === "") {
                registerTask();
              } else {
                copyTask(showAddTaskModal.copyFromId)
              }
            }}
            action="Legg til"
            primaryButtonProps={{ disabled: showAddTaskModal.spin }}
          >
            <SolidInput
              onChange={(e) => {
                let name = e.target.value.trim();
                setNameInput(name);

                if (name.length <= 0) {
                  setShowInvalidNameError({ "show": true, "msg": "Et navn er påkrevd, skriv inn et navn på bemanningsplanen" });
                  return;
                }

                if (name.length > 40) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på bemanningsplan kan ikke være lenger enn 40 tegn" });
                  return;
                }

                if (!validCharactersRegex().test(name)) {
                  setShowInvalidNameError({ "show": true, "msg": "Navn på bemanningsplan kan ikke inneholde ugyldige tegn" });
                  return;
                }

                setShowInvalidNameError({ "show": false, "msg": "" });

              }}
              placeholder="Navn"
            />
            <Checkbox
                mt={"10px"}
                label={"Ekskluder tilpasset bemanningsplan"}
                onChange={() => setShowTaskModal({...showAddTaskModal, excludeCustomBP: !showAddTaskModal.excludeCustomBP})}
            />
            {showInvalidNameError.show && (
              <p style={{ color: "#BF3D2B" }}>{showInvalidNameError.msg}</p>
            )}
            {showAddTaskModal.spin && <p><Spinner label={"Laster. Vennligst vent"}/></p>}
          </Modal>
        )
      }
      {
          showErrorModal.show && (
              <Modal
                  action="Ta meg dit"
                  done={() => {
                    setShowErrorModal({taskId: "", show: false});
                    history.push(`/avdelinger/${departmentId}/bemanningsplaner/${showErrorModal.taskId}`);
                  }}
                  cancel={() => {
                    setShowErrorModal({taskId: "", show: false});
                  }}
                  cancelButtonProps={{
                    style: { display: "none" }
                  }}
                  secondaryButtonProps={{
                    children: 'Avbryt',
                    onClick: () => setShowErrorModal({taskId: "", show: false})
                  }}
              >
                <div>
                  <h3>Bemanningsplanen er ugyldig!</h3>
                  <p>Ønsker du å gå til bemanningsplanen for å se feilmelding?</p>
                </div>
              </Modal>
          )
      }
      {
        showStoppedModal && (
          <Modal
            title="Generering har stoppet"
            done={() => {
              setShowStoppedModal(false);
            }}
            cancel={null}
          >
            <p>Generering har stoppet uventet under kjøring. Vennligst kopier bemanningsplanen og start på nytt.</p>
          </Modal>
        )
      }
      {showEmployeeModal && (
        <Modal
          title="Ingen ansatte opprettet"
          cancel={() => {

          }}
          cancelButtonProps={{
            style: { display: "none" }
          }}
          done={() => {
            history.push(`/avdelinger/${departmentId}/ansatte`);
          }}
          action="Ja, gå til ansatte"
          secondaryButtonProps={{
            children: 'Nei, avbryt',
            onClick: () => {

              setShowEmployeeModal(false);
            }
          }}
        >
          <p>Denne avdelingen har ingen ansatte opprettet, vil du opprette disse nå?</p>
        </Modal>
      )}
      {showConfirmGenerateModal.show && (
        <Modal
          cancelButtonProps={{
            children: 'Avbryt'
          }}
          done={() => {
            generatePlan(showConfirmGenerateModal.generateId)
            setShowConfirmGenerateModal({ "show": false, "generateId": "" });
          }}
          cancel={(e) => {
            if (!e) return;
            setShowConfirmGenerateModal({ "show": false, "generateId": "" });
          }}
          action="Start generering"
        >
          <p style={{ fontSize: "20px" }}>Er du sikker på at du vil starte generering av denne bemanningsplanen?</p>
        </Modal>
      )}
    </div >
  );
};

const BigInfo = styled.div`
  font-family: Montserrat Alternates;
  font-size: 18px;
`;

export default Tasks;
