import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, notifications } from "rendition";
import { DayShift } from "./day-shift";
import styled from "styled-components";
import Tooltip from "../lib/tooltip";
import {createPatch} from "rfc6902";
import { useForm } from "react-hook-form";
import {
  emptyDemand,
  isTaskReadOnly, isTaskStatusReadOnly,
  shiftTypeLimitInCustomBp,
  sortShiftCodesByDaySegment
} from "../lib/common";
import { dynoapeAPI } from "../api/dynoape";
import _ from 'lodash';
import Select from "react-select";
import {ShiftStats} from "./shift-stats";
import { applyPatch } from "rfc6902";
import moment from "moment";

export const DayInputCustom = ({
  day,
  date,
  selectedShift,
  setSelectedShift,
  department,
  task,
  paramDailyCoverPatches,
  positions,
  traitNames,
  customDate,
  setCoverDemands,
  doPatch,
  setTask,
  validateButton,
  setUpdateTaskOngoing,
  employees
}) => {
  const {
    register: customRegister,
    unregister: customUnregister,
    handleSubmit: customHandleSubmit,
    trigger: customTrigger,
    setValue: customSetValue,
    formState: { errors: customErrors, isDirty: customIsDirty },
    watch: customWatch
  } = useForm({ defaultValues: task, mode: 'onBlur' });
  const [notification, setNotification] = useState("");
  const [dailyCoverPatches, setDailyCoverPatches] = useState(paramDailyCoverPatches ? JSON.parse(JSON.stringify(paramDailyCoverPatches)) : {});
  const [inputData, setInputData] = useState({});
  const [highlightedInputs, setHighlightedInputs] = useState();
  const [loaded] = useState(true);
  const [showResetShiftModal, setShowResetShiftModal] = useState(false);
  const { departmentId, taskId } = useParams();
  const data = customWatch();
  const dataRef = useRef(data);
  dataRef.current = data;
  const isDirtyRef = useRef(customIsDirty);
  isDirtyRef.current = customIsDirty;
  const [shifts, setShifts] = useState(task.config.weeklyCoverDemands[day] ? Object.keys(task.config.weeklyCoverDemands[day]) : {});
  const [isSaving, setIsSaving] = useState(false);

  const [showStatsPage, setShowStatsPage] = useState(task.status !== "FROZEN");

  const getTaskWithPatchedWeeklyDemands = () => {
    const startOfWeek = moment(date).startOf('isoWeek');
    const _task = _.cloneDeep(task)
    const weekly = _task.config.weeklyCoverDemands;
    const holidays = _task.config.holidays;
    Array.from({ length: 7 }, (_, i) => {
          const date = startOfWeek.clone().add(i, 'days');
          const formattedDate = date.format('YYYY-MM-DD');
          const day = date.format("dddd").toUpperCase();
          if(holidays.includes(formattedDate)) {
            weekly[day] = JSON.parse(JSON.stringify((weekly['HOLIDAYS'])))
          }
          if(dailyCoverPatchesRef.current[formattedDate]) {
            applyPatch(weekly[day], dailyCoverPatchesRef.current[formattedDate])
          }
        }
    );

    _task.config.weeklyCoverDemands = weekly;
    return _task;
  }

  const inputDataRef = useRef();
  inputDataRef.current = inputData;
  const dailyCoverPatchesRef = useRef();
  dailyCoverPatchesRef.current = dailyCoverPatches;

  useEffect(() => {
    setTimeout(() => {
      setHighlightedInputs(paramDailyCoverPatches?.[customDate]?.map(patch => {
        return patch.path;
      }));
      customSetValue(`config.weeklyCoverDemands`, JSON.parse(JSON.stringify(task.config.weeklyCoverDemands)));
      let shifts_tmp = Object.keys(task.config.weeklyCoverDemands[day]);
      paramDailyCoverPatches?.[customDate]?.forEach(patch => {
        if(patch.op === 'add' && (patch.path.match(new RegExp("/", "g")) || []).length === 1) {
          let code = patch.path.replaceAll("/", "");
          if(!shifts_tmp.includes(code)) {
            shifts_tmp.push(code);
          }
        }
        customSetValue(`config.weeklyCoverDemands.${day}${patch.path.split("/").join(".")}`, patch.value);
      });
      setShifts(shifts_tmp);
      setDailyCoverPatches(JSON.parse(JSON.stringify(paramDailyCoverPatches)));
    }, 0)
  }, [customDate, task, paramDailyCoverPatches]);

  useEffect(() => {
    if (notification === "") return;
    notifications.removeNotification(notification.id - 1);

    notifications.addNotification({
      id: notification.id,
      content: notification.msg,
      duration: 2000,
      container: "top-center",
      type: notification.type
    });
  }, [notification]);

  const save = async (data) => {
    setUpdateTaskOngoing(true);
    const ok = await customTrigger();
    if (ok && Object.keys(customErrors).length === 0 && !isTaskStatusReadOnly(task.status)) {
      const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
        return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
      });
      await putCoverPatches(taskPatch, "Tilpasset bemanningsplan lagret", true);
    }
    setUpdateTaskOngoing(false);
  };

  const putCoverPatches = async (taskPatch, message, force) => {
    let newdailyCoverPatches = JSON.parse(JSON.stringify(dailyCoverPatchesRef.current));

    if (taskPatch.length > 0) {
      newdailyCoverPatches[customDate] = taskPatch;
    } else {
      delete newdailyCoverPatches[customDate];
    }

    let addedCodes = [];
    let removedCodes = [];
    newdailyCoverPatches[customDate].forEach(patch => {
      const code = patch.path.split("/")[1];
      if(patch.op === "add") {
        addedCodes.push(code);
        removedCodes = removedCodes.filter(c => c !== code)
      } else if (patch.op === "remove") {
        addedCodes = addedCodes.filter(c => c !== code);
        removedCodes.push(code);
      }
    })

    newdailyCoverPatches[customDate] = [...new Set(newdailyCoverPatches[customDate].filter(patch => {
      const code = patch.path.split("/")[1];
      if(patch.op !== "remove" && removedCodes.includes(code)) return false;
      if(patch.op !== "add" && addedCodes.includes(code)) return false;
      return true;
    }))]

    if (!_.isEqual(dailyCoverPatchesRef.current, newdailyCoverPatches) || force) {

      let patch = createPatch(dailyCoverPatchesRef.current, newdailyCoverPatches).map(p => {
        p.path = "/config/dailyCoverPatches" + p.path;
        return p;
      })

      const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}?includeTaskHolidays=true`, patch);

      if (response) {
        setDailyCoverPatches(response.config.dailyCoverPatches);
        setCoverDemands(response.config.dailyCoverPatches);
        setHighlightedInputs(response.config.dailyCoverPatches?.[customDate]?.map(patch => {
          return patch.path;
        }));
        setNotification({ "id": "custom", "msg": message, "type": "success" });
      }
    }
  };

  const deletePatch = async (input) => {
    setUpdateTaskOngoing(true)
    let path = "/".concat(input.replaceAll(day.concat("."), "").replaceAll(".", "/"))
    let newDailyCoverPatches = _.cloneDeep(dailyCoverPatchesRef.current);
    newDailyCoverPatches[customDate] = newDailyCoverPatches[customDate].filter(patch => {
      return !patch.path.startsWith(path);
    });

    let patch = createPatch(dailyCoverPatchesRef.current, newDailyCoverPatches).map(p => {
      p.path = "/config/dailyCoverPatches" + p.path;
      return p;
    })

    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, patch);
    setTask(response)
    setUpdateTaskOngoing(false)
  };

  const shiftHighlighted = (sh) => {
    return highlightedInputs?.filter(p => p.startsWith("/".concat(sh).concat("/"))).length > 0;
  }

  const shiftCodeIsRemoved = (sh) => {
    return paramDailyCoverPatches?.[customDate]?.find(op => op.op === 'remove' && op.path.split("/")[1] === sh) !== undefined
  }

  const shiftButtons = sortShiftCodesByDaySegment(shifts, Object.values(task.config.shiftTypes)).map(shift =>
    <div key={shift} style={{float: "left", paddingBottom: "15px"}}>
      <Button className={
          !Object.keys(task.config.weeklyCoverDemands[day]).includes(shift) && selectedShift !== shift
              ? "input-highlight"
              : (shiftHighlighted(shift) && selectedShift !== shift ? "input-partial-highlight" : "")}

              primary={selectedShift === shift && !shiftCodeIsRemoved(shift)}
              disabled={shiftCodeIsRemoved(shift)}
              data-for={shift}
              data-tip="Her ser du hvilken vakt du skal spesifisere/står i. Dersom du ikke har f.eks. “Langvakt/Mellomvakt”, så settes det null som tall i minimum- og maksimum antall ansatte"
              onClick={() => {
                setSelectedShift(shift);
                setShowStatsPage(false);
              }}>{shift}
      </Button>
      <Tooltip id={shift} />
    </div>
  );

  const appendDefaultTraitsAndPositionCovers = (demand) => {
    if(traitNames) {
      Object.keys(traitNames).forEach(t => {
        demand.traitRequirements[t] = {traitId: t, operator: "min", value: 0}
      });
    }
    if(positions) {
      positions.forEach(p => {
        demand.positionRequirements[p.id] = 0;
      });
    }
    return demand;
  }

  const addShiftType = async (sh) => {
    setUpdateTaskOngoing(true)
    setIsSaving(true)
    let baseSh = task.config.shiftTypes[sh].daySegment;
    const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
      return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
    });
    taskPatch.push({op: "add", path: `/${sh}`, value: appendDefaultTraitsAndPositionCovers(JSON.parse(JSON.stringify(emptyDemand(baseSh))))})

    await putCoverPatches(taskPatch, "Vaktkode lagt til");
    setInputData(task)
    setIsSaving(false)
    setUpdateTaskOngoing(false)
  }

  const removeShiftType = async (sh) => {
    setIsSaving(true)
    setUpdateTaskOngoing(true)
    const taskPatch = createPatch(task.config.weeklyCoverDemands[day], data.config.weeklyCoverDemands[day]).filter(operation => {
      return ((operation.op !== "remove" || operation.path.includes("employees")) && operation.path !== "/name")
    }).filter(p => p.path.split("/")[1] !== sh);
    taskPatch.push({op: "remove", path: `/${sh}`})
    await putCoverPatches(taskPatch, "Vakttype fjernet");

    setShifts(shifts.filter(s => s !== sh));
    setInputData(task)
    setSelectedShift(null)
    setIsSaving(false)
    setUpdateTaskOngoing(false);
    customUnregister(`config.weeklyCoverDemands.${day}.${sh}`)
  }

  useEffect(() => {
    return () => {
      if(isDirtyRef.current) {
        save(dataRef.current);
      }
    }
  }, [])

  return (
    <form onSubmit={customHandleSubmit(save)} onChange={() => { customTrigger(); }}>
      <DayCard>
        <div style={{maxWidth: "370px", minWidth: "370px", marginRight: "70px"}}>
          {shiftButtons}
        </div>
        {task.status !== "FROZEN" && <div style={{marginLeft: "30px"}}><Button primary={showStatsPage} success={!showStatsPage} onClick={() => {
          setShowStatsPage(!showStatsPage);
          setSelectedShift(null);
        }}>{!showStatsPage ? "Vis" : "Skjul"} sammendrag</Button></div>}
        <Button tertiary plain style={{ gap: "1em", float: "left", height: "40px"}}
                 disabled={!((!isTaskReadOnly(task) && Object.keys(task.config.weeklyCoverDemands[day]).includes(selectedShift)) && (
                     highlightedInputs?.filter(p => p.startsWith("/".concat(selectedShift).concat("/"))).length>0)) }
                 onClick={() => setShowResetShiftModal(true)}>
          <img src="/assets/icons/roll.png" />
          Tilbakestill vakt
          {showResetShiftModal && <Modal
            title="Er du sikker på at du vil fjerne tilpasningene for denne vakten?"
            cancel={() => setShowResetShiftModal(false)}
            done={() => {
              deletePatch(selectedShift);
              setShowResetShiftModal(false);
            }}
            action="Tilbakestill"
            cancelButtonProps={{
              children: 'Avbryt',
            }}
          />}
        </Button>
        <Button mr="5px" ml="5px" primary type="submit" >Lagre</Button>
        {!isTaskReadOnly(task) && validateButton}
        {!isTaskReadOnly(task) && shifts.length < shiftTypeLimitInCustomBp() && <div style={{marginLeft: "20px"}}>
          <Select
              isDisabled={isSaving}
              placeholder={`Legg til vaktkode (maks. ${shiftTypeLimitInCustomBp()})`}
              isSearchable={false}
              controlShouldRenderValue={false}
              options={sortShiftCodesByDaySegment(Object.keys(task.config.shiftTypes), Object.values(task.config.shiftTypes))
                  ?.filter(sh => !shifts.includes(sh) || shiftCodeIsRemoved(sh))
                  ?.map(sh => ({label: sh, value: sh}))}
              onChange={(e) => {
                addShiftType(e.value);
                setSelectedShift(e.value)
                setShowStatsPage(false)
              }}
              styles={{ control: base => ({ ...base, borderWidth: "3px", borderColor: "#222"}), placeholder: base => ({...base, color: "#222"})}}
          >
          </Select>
        </div>}
      </DayCard>

      {loaded && selectedShift && shifts.includes(selectedShift) && !shiftCodeIsRemoved(selectedShift) &&
          <DayShift date={date} custom={true} register={customRegister} unregister={customUnregister} task={task} day={day} shift={selectedShift} errors={customErrors}
                    positions={positions} traitNames={traitNames} watch={customWatch} highlightedInputs={highlightedInputs} deletePatch={deletePatch}
                    doPatch={doPatch} dailyCoverPatches={dailyCoverPatches} setValue={customSetValue}
                    removeShiftType={removeShiftType} department={department}/>}
      {showStatsPage && <ShiftStats task={getTaskWithPatchedWeeklyDemands()} employees={employees} customDay={customDate}></ShiftStats>}
    </form>
  );
};

const DayCard = styled.div`
  display: flex;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 25px;
  padding: 25px;
  button {
    margin-right: 10px;
  }
  border-top-left-radius: 0px;
  width: 1350px;
`;