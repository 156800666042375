import React, { useState } from "react";
import { Button, Modal, Checkbox } from "rendition";
import styled from "styled-components";
import { createPatch } from "rfc6902";
import { CardColumn, InputDivider } from "../lib/styled-components";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router";
import { notifications } from "rendition";
import {isTaskStatusReadOnly, norwegianDays, norwegianShiftNames} from "../lib/common";
import Tooltip from "../lib/tooltip";
import Select from "react-select";

const DayCopy = ({
  task,
  setTask,
  unregister,
  setValue,
  errors,
  save,
  selectedDay,
  taskStatus
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [originDay, setOriginDay] = useState();
  const [copyToDays, setCopyToDays] = useState([]);
  const { departmentId, taskId } = useParams();

  const shiftOptions = Object.keys(task.config.weeklyCoverDemands[selectedDay])?.map(sh => ({label: sh, value: sh}));

  const dayOptions = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY", "HOLIDAYS"];

  const [originShifts, setOriginShifts] = useState(shiftOptions);

  const [overwriteDays, setOverwriteDays] = useState(false);

  const toggleAllCheckboxes = (selected) => {
    if (selected) {
      setCopyToDays(dayOptions);
    } else {
      setCopyToDays([]);
    }
  };

  const handleCheckboxChange = (day, selected) => {
    let newArray = [...copyToDays];
    if (selected === false && copyToDays.includes(day)) {
      newArray = newArray.filter(fDay => fDay !== day);
      setCopyToDays(newArray);
    } else if (selected) {
      newArray.push(day);
      setCopyToDays(newArray);
    }
  };

  const copyDays = async () => {
    await save();
    if (copyToDays.indexOf(originDay) >= 0)
      copyToDays.splice(copyToDays.indexOf(originDay), 1);

    if (copyToDays.length === 0)
      return

    const updatedTask = { config: { weeklyCoverDemands: {} } };

    copyToDays.forEach(day => {
      updatedTask.config.weeklyCoverDemands[day] = {};
      if(overwriteDays) {
        updatedTask.config.weeklyCoverDemands[day] = task.config.weeklyCoverDemands[originDay];
      } else {
        originShifts?.map(s => s.value).forEach(shift => {
          updatedTask.config.weeklyCoverDemands[day][shift] = task.config.weeklyCoverDemands[originDay][shift];
        });
      }
    });

    const taskPatch = createPatch(task, updatedTask)
        .filter(operation => { return (operation.op !== "remove"
            || operation.path.includes("traitRequirements") || operation.path.split("/").length === 5) });

    copyToDays.forEach(day => {
      originShifts?.map(s => s.value).forEach(shift => {
        task.config.weeklyCoverDemands[day][shift] = JSON.parse(JSON.stringify(task.config.weeklyCoverDemands[originDay][shift]));
        setValue(`config.weeklyCoverDemands.${day}.${shift}`, JSON.parse(JSON.stringify(task.config.weeklyCoverDemands[originDay][shift])));
      });
      if(overwriteDays) {
        let removedShifts = Object.keys(task.config.weeklyCoverDemands[day]).filter(sh => !originShifts?.map(s => s.value).includes(sh))
        removedShifts.forEach(sh => {
          delete task.config.weeklyCoverDemands[day][sh];
          unregister(`config.weeklyCoverDemands.${day}.${sh}`);
        })
      }
    });
    setValue(`config.weeklyCoverDemands`, task.config.weeklyCoverDemands);
    let resp = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}`, taskPatch);
    setTask(resp)

    notifications.addNotification({
      content: "Dager kopiert",
      duration: 2000,
      container: "center",
      type: "success"
    });
    setOriginDay(selectedDay);
    setCopyToDays([]);
    setOverwriteDays(false);
    setShowModal(false);
  };

  const Errors = () => {
    if (errors.config.weeklyCoverDemands) {
      console.error(errors.config.weeklyCoverDemands);
      let errorList = Object.keys(errors.config.weeklyCoverDemands)?.map((day) => Object.keys(errors.config.weeklyCoverDemands[day])?.map((shift, index) => <p key={index}>Feil på {norwegianDays(day)} - {norwegianShiftNames(shift)}</p>));
      return (
        errorList
      );
    }
    return (<p>Feil på innstillinger</p>);
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      {!isTaskStatusReadOnly(taskStatus) && <Button className={"BpButton"} type="submit" mr="10px" tertiary onClick={() => {
        if (Object.keys(errors).length === 0) {
          const day = selectedDay ? selectedDay : "MONDAY";
          setOriginDay(day);
          setOriginShifts(Object.keys(task.config.weeklyCoverDemands[day])?.map(sh => ({value: sh, label: sh})));
          setShowModal(true);
        } else {
          setShowErrorModal(true);
        }
      }}
        data-for="copy-days"
        data-tip="Dersom du ønsker å kopiere denne dagen til andre dager klikker du her. Vi anbefaler at du fyller ut f.eks. alle vakter på en dag før du kopierer, for så å korrigere på de andre dagene"
      >
        Kopier dager og vakter</Button>}
      <Tooltip id="copy-days" />
      {showModal && (
        <Modal
          style={{ width: "540px" }}
          title="Kopier dager og vakter"
          action="Kopier til dager"
          done={() => {
            copyDays();
          }}
          primaryButtonProps={{
            disabled: copyToDays.length === 0 || originShifts.length === 0
          }}
          cancel={() => {
            setCopyToDays([]);
            setOverwriteDays(false);
            setShowModal(false);
          }}
          cancelButtonProps={{
            children: 'Avbryt'
          }}
        >
          <CardColumn>
            <div style={{marginBottom: "20px"}}>
              <label htmlFor={"overwrite"}>Ønsker du å overskrive dagene du kopierer til?</label>
              <Checkbox
                  reverse
                  toggle
                  id="overwrite"
                  checked={overwriteDays}
                  onChange={() => setOverwriteDays(!overwriteDays)}
              />
            </div>
            <SelectContainer>
              <label htmlFor={"originDay"}>Hvilken dag ønsker du å kopiere fra?</label>
              <select
                id="originDay"
                placeholder="Velg dag"
                value={originDay}
                onChange={(e) => {
                  setOriginDay(e.target.value);
                  setOriginShifts(Object.keys(task.config.weeklyCoverDemands[e.target.value])?.map(sh => ({label: sh, value: sh})))}}
                style={{ width: "200px" }}
              >
                <option value="MONDAY">Mandag</option>
                <option value="TUESDAY">Tirsdag</option>
                <option value="WEDNESDAY">Onsdag</option>
                <option value="THURSDAY">Torsdag</option>
                <option value="FRIDAY">Fredag</option>
                <option value="SATURDAY">Lørdag</option>
                <option value="SUNDAY">Søndag</option>
                <option value="HOLIDAYS">Helligdager</option>
              </select>
            </SelectContainer>

            <SelectContainer>
              <label htmlFor={"originDay"}>Hvilken vakter ønsker du å kopiere fra?</label>
              <Select
                id="originShifts"
                placeholder="Velg vakter"
                value={originShifts}
                onChange={(e) => setOriginShifts(e)}
                options={Object.keys(task.config.weeklyCoverDemands[originDay])?.map(sh => ({label: sh, value: sh}))}
                closeMenuOnSelect={false}
                isMulti={true}
                isSearchable={false}
                noOptionsMessage={() => "Alle vakter valgt"}
              />
            </SelectContainer>
            <InputCard>
              <span>Kopier til følgende dager:</span>
              <div style={{ "left": "24px", "position": "relative" }}>
                <Checkbox
                  label="Velg alle dager"
                  checked={copyToDays.length >= 7}
                  onChange={(e) => toggleAllCheckboxes(e.target.checked)}
                />
              </div>
            </InputCard>

            <InputCard>
              {originDay !== "MONDAY" && <CheckboxContainer>
                <Checkbox
                  label="Mandag"
                  checked={copyToDays.includes("MONDAY")}
                  onChange={(e) => handleCheckboxChange("MONDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "TUESDAY" && <CheckboxContainer>
                <Checkbox
                  label="Tirsdag"
                  checked={copyToDays.includes("TUESDAY")}
                  onChange={(e) => handleCheckboxChange("TUESDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "WEDNESDAY" && <CheckboxContainer>
                <Checkbox
                  label="Onsdag"
                  checked={copyToDays.includes("WEDNESDAY")}
                  onChange={(e) => handleCheckboxChange("WEDNESDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "THURSDAY" && <CheckboxContainer>
                <Checkbox
                  label="Torsdag"
                  checked={copyToDays.includes("THURSDAY")}
                  onChange={(e) => handleCheckboxChange("THURSDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "FRIDAY" && <CheckboxContainer>
                <Checkbox
                  label="Fredag"
                  checked={copyToDays.includes("FRIDAY")}
                  onChange={(e) => handleCheckboxChange("FRIDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "SATURDAY" && <CheckboxContainer>
                <Checkbox
                  label="Lørdag"
                  checked={copyToDays.includes("SATURDAY")}
                  onChange={(e) => handleCheckboxChange("SATURDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "SUNDAY" && <CheckboxContainer>
                <Checkbox
                  label="Søndag"
                  checked={copyToDays.includes("SUNDAY")}
                  onChange={(e) => handleCheckboxChange("SUNDAY", e.target.checked)}
                />
              </CheckboxContainer>
              }

              {originDay !== "HOLIDAYS" && <CheckboxContainer>
                <Checkbox
                    label="Helligdager"
                    checked={copyToDays.includes("HOLIDAYS")}
                    onChange={(e) => handleCheckboxChange("HOLIDAYS", e.target.checked)}
                />
              </CheckboxContainer>
              }
            </InputCard>
          </CardColumn>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          title="Feil i bemanningsplan"
          done={() => {
            setShowErrorModal(false);
          }}
          action="Lukk"
        >
          <p>Det er en feil i bemanningsplanen, vennligst fiks den før du prøver å kopiere dager</p>
          <Errors />
        </Modal>
      )}
    </div>
  );
};

const SelectContainer = styled.div`
  padding-bottom:20px;
`;

const InputCard = styled(InputDivider)`
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 10px;
  width: 400px;
  min-height: 30px;
`;

const CheckboxContainer = styled.div`
  width: 100px;
  & > div > label > div {
    flex-direction: row;
  }
`;

export default DayCopy;