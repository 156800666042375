import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {Button, Flex} from "rendition";
import { Card, CardRow } from "../lib/styled-components";
import {
  formatStringDateTime,
  isTaskInProcessState,
  TaskCardSpinner,
  TaskCardWaitingSpinner,
  taskStuck
} from "../lib/common";
import Tooltip from "../lib/tooltip";

const TaskCard = props => {
  return (
      <TaskCardContainer style={{ backgroundColor: props.task.status === "FROZEN" ? "#a5c5d9": "white" }}>
        <TaskCardContainerRow>
          <TaskName>{props.task.name} {props.task.status === "FROZEN" ? " (fryst for redigering)" : ""}</TaskName>
          <div style={{ marginLeft: "auto", position: "relative", backgroundColor: props.task.status === "FROZEN" ? "#a5c5d9": "white" }}>
            <Flex flexDirection={"column"}>
              {(["FINISHED"].includes(props.task.status) || props.task.status === "PROCESSING" && taskStuck(props.task)) &&
                  <div style={{
                    marginRight: "10px",
                    marginBottom: "40px",
                    position: "relative",
                    fontFamily: "Montserrat Alternates",
                    fontSize: "18px",
                    color: "#26565B"
                  }}>
                    Startet: {formatStringDateTime(props.task.started)}&emsp;&emsp;Resultater: {props.task.result ? props.task.result.resultNr + 1 : "ingen"}&emsp;&emsp;Avvik: {props.task.result ? props.task.result.nrOfBreaches : "ingen"}
                  </div>
              }
              {["PROCESSING", "QUEUED", "STARTING"].includes(props.task.status) &&
                <div style={{ marginLeft: "20px", position: "relative" }}>
                  {props.task.status === "PROCESSING" && !taskStuck(props.task) && <TaskCardSpinner label={`Generering pågår (${props.task.result ? props.task.result.resultNr + 1: "ingen"} resultat(er), ${props.task.result ? props.task.result.nrOfBreaches : "ingen"} avvik)`}></TaskCardSpinner>}
                  {props.task.status === "QUEUED" && <TaskCardWaitingSpinner label="Venter på at generering skal starte"></TaskCardWaitingSpinner>}
                  {props.task.status === "STARTING" && <TaskCardWaitingSpinner label="Starter generering"></TaskCardWaitingSpinner>}
                </div>
              }
              <div style={{float: "right", marginBottom: "30px"}}>
                <Button primary onClick={() => props.setShowTaskModal({ "show": true, "copyFromId": props.task.id, spin: false })} style={{float: "right"}} mr="10px" data-for="copy" data-tip="Dersom du ønsker å kopiere en eksisterende Bemanningsplan klikker du på “kopier” den aktuelle Bemanningsplanen">Kopier</Button><Tooltip id="copy" />
                {props.task.status === "PROCESSING" && <ResultPage departmentId={props.departmentId} taskId={props.task.id} text="Se fremgang" type="progressing"></ResultPage>}
                {(props.task.status === "PROCESSING" && taskStuck(props.task)) && <Button style={{float: "right"}} mr="10px" danger onClick={() => props.showStopped()}>Generering har stoppet</Button>}
                {props.task.status === "ERROR_IN_CONFIG" && <Button danger mr="10px" style={{float: "right"}} onClick={() => props.showError(props.task)}>Generering feilet</Button>}
                {props.task.status === "ERROR_GENERIC" && <Button style={{float: "right"}} danger mr="10px">Generering stoppet uventet</Button>}
                {props.task.status === "FINISHED_WITH_NO_RESULTS" && <NavLink style={{ marginLeft: "0px", float: "right" }} className="nav-link" to={`/avdelinger/${props.departmentId}/bemanningsplaner/${props.task.id}`}><Button danger primary>Ferdig uten resultat</Button></NavLink>}
                {props.task.status === "FINISHED" && <ResultPage departmentId={props.departmentId} taskId={props.task.id} text="Generering ferdig" type="success"></ResultPage>}
                <NavLink style={{ marginLeft: "0px", float: "right" }} className="nav-link" to={`/avdelinger/${props.departmentId}/bemanningsplaner/${props.task.id}`} data-for="copy" data-tip="Dersom du vil se på Bemanningsplanen klikker du her. Du kan ikke legge til endringer i en allerede generert turnus. Da må du kopiere den og legge inn endringer, og generere på nytt"><Button primary>Åpne</Button></NavLink><Tooltip id="open" />
                {(!isTaskInProcessState(props.task) || taskStuck(props.task)) && <Button style={{float: "right"}} mr={"10px"} danger onClick={() => props.setShowDeleteTaskModal({"show": true, "taskId": props.task.id, "name": props.task.name})}>Slett</Button>}

              </div>
            </Flex>
          </div>
        </TaskCardContainerRow>
        {props.task.comment !== undefined && props.task.comment !== null && props.task.comment !== "" &&
            <div style={{marginTop: "-10px", fontFamily: "Montserrat Alternates", whiteSpace: "pre-line"}}>
              {props.task.comment}
              <br/><br/><br/>
            </div>
        }
      </TaskCardContainer>
  );
};

export default TaskCard;

const TaskCardContainer = styled(Card)`
  min-height: 100px;
  margin-bottom: 15px;
`;

const TaskCardContainerRow = styled(CardRow)`
  padding-top: 30px;
  padding-right: 20px;
`;

const TaskName = styled.h3`
  margin-top: 5px;
`;

const ResultPage = props => {
  return (<NavLink style={{ marginLeft: "0px", float: "right" }}
                   className="nav-link"
                   to={`/avdelinger/${props.departmentId}/turnusplaner/${props.taskId}`}>
    {props.type === 'success' && <Button success data-for={props.taskId} data-tip="Generering av turnus er ferdig. Ved å klikke her kommer du direkte til det beste resultatet som Dynamon har generert">{props.text}</Button>}
    {props.type === 'progressing' && <Button info data-for={props.taskId} data-tip="Når Dynamon genererer turnus vil du kunne til enhver tid se de 10 beste resultatene den har funnet her">{props.text}</Button>}
    <Tooltip id={props.taskId} />
  </NavLink>)
}
