import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "rendition";
import { createPatch } from "rfc6902";
import { CardColumn } from "../lib/styled-components";
import { dynoapeAPI } from "../api/dynoape";
import { useParams } from "react-router";
import { norwegianDays, norwegianShiftNames } from "../lib/common";
import Calendar from 'rc-year-calendar';
import moment from "moment";
import _ from 'lodash';
import Tooltip from "../lib/tooltip";

const CustomDayOrWeekCopy = ({
  department,
  errors,
  taskStartDate,
  taskWeeks,
  task,
  setTask,
  setUpdateTaskOngoing
}) => {
  const [copyFullWeeks, setCopyFullWeeks] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment(taskStartDate).format("YYYY"));
  const [originDay, setOriginDay] = useState();
  const [copyToDaysOrWeeks, setCopyToDaysOrWeeks] = useState([]);
  const { departmentId, taskId } = useParams();
  const [turnusDates, setTurnusDates] = useState([]);

  const yearRef = useRef();
  const originDayRef = useRef();
  const copyToDaysOrWeeksRef = useRef();

  yearRef.current = selectedYear;
  originDayRef.current = originDay;
  copyToDaysOrWeeksRef.current = copyToDaysOrWeeks;

  useEffect(() => {
    setTurnusDates(Array.from({ length: taskWeeks * 7 }, (_, d) =>
        moment(taskStartDate).add(d, 'days').format('YYYY-MM-DD')
    ));
  }, []);


  const doCopy = async () => {
    setUpdateTaskOngoing(true)
    let existingPatches = _.cloneDeep(task.config.dailyCoverPatches);
    let copies = {};

    if(copyFullWeeks) {
      const fromDays = Array.from(
          {length: 7}, (_, i) =>
              moment(originDay)
                  .startOf('isoWeek')
                  .add(i, 'days')
                  .format('YYYY-MM-DD')
      );
      fromDays.forEach(date => {
        const fromMom = moment(date);
        for (const copyTo of copyToDaysOrWeeks) {
          let firstDayOfCopyToWeek = moment(copyTo).startOf('isoWeek');
          for (let m = firstDayOfCopyToWeek.clone(); m.isoWeek() === firstDayOfCopyToWeek.isoWeek(); m.add(1, 'd')) {
            if (fromMom.day() === m.day()) {
              copies[m.format("YYYY-MM-DD")] = Object.keys(existingPatches).includes(date) ? existingPatches[date] : [];
            }
          }
        }
      });
    } else {
      for (const copyTo of copyToDaysOrWeeks) {
        copies[copyTo] = Object.keys(existingPatches).includes(originDay) ? existingPatches[originDay] : [];
      }
    }

    let patch = createPatch(_.cloneDeep(existingPatches), Object.assign(existingPatches, copies)).map(p => {
      p.path = "/config/dailyCoverPatches" + p.path;
      return p;
    })
    const response = await dynoapeAPI.patch(`/api/v1/department/${departmentId}/task/${taskId}?includeTaskHolidays=true`, patch);
    if (response) {
      setTask(response);
    }

    setOriginDay();
    setCopyToDaysOrWeeks([]);
    setUpdateTaskOngoing(false)
  };

  const Errors = () => {
    if (errors.config.weeklyCoverDemands) {
      console.error(errors.config.weeklyCoverDemands);
      let errorList = Object.keys(errors.config.weeklyCoverDemands)?.map((day) => Object.keys(errors.config.weeklyCoverDemands[day])?.map((shift, index) => <p key={index}>Feil på {norwegianDays(day)} - {norwegianShiftNames(shift)}</p>));
      return (
        errorList
      );
    }
    return (<p>Feil på innstillinger</p>);
  };

  return (
      <div style={{ marginLeft: "auto", height: "65px"}}>
        <Button
            data-for={"copy-day-button"}
            data-tip={"Her kan du kopiere en enkeltdag til flere dager"}
            className={"BpButton"}
            type="submit" mr="10px"
            tertiary
            onClick={() => {
              if (Object.keys(errors).length === 0) {
                setCopyFullWeeks(false);
                setShowModal(true);
              } else {
                setShowErrorModal(true);
              }
            }}>
          Kopier dag
        </Button>
        <Tooltip id="copy-day-button" />
        <Button
            data-for={"copy-week-button"}
            data-tip={"Her kan du kopiere en enkeltuke til flere uker"}
            className={"BpButton"}
            type="submit"
            mr="10px"
            tertiary
            onClick={() => {
              if (Object.keys(errors).length === 0) {
                setCopyFullWeeks(true)
                setShowModal(true);
              } else {
                setShowErrorModal(true);
              }
            }}>
          Kopier uke
        </Button>
      <Tooltip id="copy-week-button" />
      {showModal && (
        <Modal
          style={{ width: "1700px" }}
          title="Kopier uke(r)"
          action={`Kopier til ${copyFullWeeks ? "uke(r)" : "dag(er)"}`}
          done={() => {
            doCopy();
            setShowModal(false);
          }}
          primaryButtonProps={{
            disabled: copyToDaysOrWeeks.length === 0
          }}
          cancel={() => {
            setOriginDay();
            setCopyToDaysOrWeeks([]);
            setShowModal(false);
          }}
          cancelButtonProps={{
            children: 'Avbryt'
          }}
        >
          <CardColumn className="calendar-task">
            <span>Klikk i kalenderen på uken du ønsker å kopiere tilpassede vakter fra, klikk i kalenderen på uken(e) det skal kopieres til.
              <p style={{color: "#BF3D2B"}}>Har du husket å lagre all endringene dine? Når du kopier uker, kopieres kun de lagrede verdiene.</p>
            </span>
            <Calendar
              minDate={moment(turnusDates[0]).toDate()}
              maxDate={moment(turnusDates[turnusDates.length - 1]).toDate()}
              language="no"
              year={selectedYear}
              onYearChanged={({ currentYear }) => setSelectedYear(currentYear)}
              displayWeekNumber={true}
              style="custom"
              dataSource={turnusDates.map(d => {
                return {
                  startDate: moment(d).toDate(),
                  endDate: moment(d).toDate()
                }
              })}
              onDayClick={({ date }) => {
                let day = copyFullWeeks ? moment(date).day(4).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD');

                if (!originDayRef.current) {
                  setOriginDay(day);
                  return;
                }

                if (originDayRef.current && originDayRef.current !== day) {
                  let newCopy = copyToDaysOrWeeksRef.current.filter(c => c !== day)
                  setCopyToDaysOrWeeks(newCopy.length !== copyToDaysOrWeeksRef.current.length
                    ? newCopy
                    : [...copyToDaysOrWeeksRef.current, day]);
                  return;
                }

                if (originDayRef.current === day && copyToDaysOrWeeksRef.current.length === 0) {
                  setOriginDay();
                }
              }}
              customDataSourceRenderer={(day) => {
                const monthNr = parseInt(day.parentNode.parentNode.parentNode.parentNode.getAttribute("data-month-id"));
                const dayNr = day.innerHTML;
                const date = `${yearRef.current}-${String(`${monthNr + 1}`).padStart(2, '0')}-${String(`${dayNr}`).padStart(2, '0')}`;
                const mom = moment(date);

                if (Object.keys(task.config.dailyCoverPatches).includes(date)) {
                  day.classList.add('day-customized');
                }

                if (Object.keys(department.countryRules.holidays).includes(date)) {
                  day.classList.add('day-red');
                }

                if(copyFullWeeks) {
                  if (originDayRef.current && mom.isoWeek() === moment(originDayRef.current).isoWeek()) {
                    day.classList.add('day-background-yellow');
                  }

                  for (let copy of copyToDaysOrWeeksRef.current) {
                    if (mom.isoWeek() === moment(copy).isoWeek()) {
                      day.classList.add('day-background-green');
                    }
                  }
                } else {
                  if (originDayRef.current && mom.format('YYYY-MM-DD') === moment(originDayRef.current).format('YYYY-MM-DD')) {
                    day.classList.add('day-background-yellow');
                  }

                  for (let copy of copyToDaysOrWeeksRef.current) {
                    if (mom.format('YYYY-MM-DD') === moment(copy).format('YYYY-MM-DD')) {
                      day.classList.add('day-background-green');
                    }
                  }
                }
              }}
            />
          </CardColumn>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          title="Feil i bemanningsplan"
          done={() => {
            setShowErrorModal(false);
          }}
          action="Lukk"
        >
          <p>Det er en feil i bemanningsplanen, vennligst fiks den før du prøver å kopiere dager</p>
          <Errors />
        </Modal>
      )}
    </div>
  );
};

export default CustomDayOrWeekCopy;