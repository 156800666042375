import React from "react";
import { Group } from "@visx/group";
import {
  scaleQuantile,
  scaleOrdinal,
  scaleLinear,
  scaleThreshold
} from "@visx/scale";
import { range } from "@visx/vendor/d3-array";
import { format } from "@visx/vendor/d3-format";
import { timeFormat } from "@visx/vendor/d3-time-format";
import {
  timeMonday,
  timeMonth,
  timeMonths,
  timeDays,
} from "@visx/vendor/d3-time";
import {
  LegendOrdinal,
  LegendQuantile,
  LegendLinear,
  LegendItem,
  LegendLabel,
  LegendThreshold,
  Legend,
} from "@visx/legend";
import {
  fullSchemeRdBu,
  getDayOfWeek,
  getMonthWeek,
  daysInMonth,
} from "../../lib/common";
import moment from "moment";

export default class CalendarHeatmap extends React.Component {
  /**
   * Generates the path for the month based on the cell size and months week
   * composition.
   *
   * @param {type} t0 - the starting date
   * @param {type} cellSize - the size of each cell
   * @return {type} the month path string
   */
  getMonthPath(t0, cellSize) {
    const d0 = getDayOfWeek(t0);
    const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0);
    const d1 = getDayOfWeek(t1);
    const w1 = getMonthWeek(t1);

    return `
      M${cellSize},${d0 * cellSize}
      H0 V${7 * cellSize}
      H${w1 * cellSize} V${(d1 + 1) * cellSize}
      H${(w1 + 1) * cellSize} V0
      H${cellSize}Z
    `;
  }

  /**
   * Helper function to generate SVG path for rounded upper triangle
   * based on x, y, width, height, and round. Upper triangle means that
   * it's top right corner is having the right angle.
   * 
   * @param {number} x coordinate. 0 is at left.
   * @param {number} y coordinate. 0 is at top.
   * @param {number} width of the triangle.
   * @param {number} height of the triangle.
   * @param {number} round of the triangle corners.
   * @return {string} path string
   */
  constructUpperTriangle(x, y, width, height, round) {
    return `
      M${x + round} ${y}
      L${x + width - round} ${y}
      Q${x + width} ${y} ${x + width} ${y + round}
      L${x + width} ${y + height - round}
      Q${x + width} ${y + height} ${x + width - round} ${y + height - round}
      L${x + round} ${y + round}
      Q${x} ${y} ${x + round} ${y}
      Z
    `;
  }
  
  /**
   * Helper function to generate SVG path for rounded bottom triangle
   * based on x, y, width, height, and round. Bottom triangle means that
   * it's bottom left corner is having the right angle.
   * 
   * @param {number} x coordinate. 0 is at left.
   * @param {number} y coordinate. 0 is at top.
   * @param {number} width of the triangle.
   * @param {number} height of the triangle.
   * @param {number} round of the triangle corners.
   * @return {string} path string
   */
  constructBottomTriangle(x, y, width, height, round) {
    return `
      M${x + round} ${y + round}
      L${x + width - round} ${y + height - round}
      Q${x + width} ${y + height} ${x + width - round} ${y + height}
      L${x + round} ${y + height}
      Q${x} ${y + height} ${x} ${y + height - round}
      L${x} ${y + round}
      Q${x} ${y} ${x + round} ${y + round}
      Z
    `;
  }

  /**
   * Retrieves the domain for the given scale information and data.
   *
   * If no scale information is present for 'ordinal' scale, the domain is
   * the set of unique values in the data.
   *
   * If no scale information is present for 'quantile' or 'linear' scale,
   * the domain is minimum and maximum numberical values in the data.
   *
   * There is no check for data to be valid. For 'ordinal' scale, we assume
   * values to be string. For 'quantile' and 'linear' scale, we assume
   * values to be numbers.
   *
   * @param {string} scaleName - The name of the scale (ordinal, quantile, linear).
   * @param {object} scale - The scale object.
   * @param {object} data - The data object.
   * @return {array|null} The domain for the scale, or null if scaleName is not recognized.
   */
  getDomain(scaleName, scale, data) {
    switch (scaleName) {
      case "ordinal":
        return typeof scale.domain !== "undefined"
          ? scale.domain
          : [
              ...new Set(
                [...data.values()].reduce(
                  (accumulator, monthMap) =>
                    accumulator.concat([...monthMap.values()]),
                  []
                ).flat()
              ),
            ];
      case "threshold":
        if (typeof scale.domain !== "undefined") {
          return scale.domain;
        }
        // falls through
      case "quantile":
      case "linear":
        return typeof scale.domain !== "undefined"
          ? scale.domain
          : [...data.values()].reduce(
              (accumulator, monthMap) => {
                let monthMax = Math.max(...monthMap.values());
                let monthMin = Math.min(...monthMap.values());
                return [
                  accumulator[0] > monthMin ? monthMin : accumulator[0],
                  accumulator[1] < monthMax ? monthMax : accumulator[1],
                ];
              },
              [9999, -9999]
            );
      default:
        return null;
    }
  }

  /**
   * Helper function to translate automatic threshold lables into Norwegian ones.
   * 
   * Turns:
   *  - "More than" => "Mer enn"
   *  - "Less than" => "Mindre enn"
   *  - "x to y" => "x til y"
   * 
   * @param {Object} label 
   * @returns {string}
   */
  translateTreshold(label) {
    if (typeof(label.extent[0]) === 'undefined') {
      return `Mindre enn ${label.extent[1]}`
    } else if (typeof(label.extent[1]) === 'undefined') {
      return `Mer enn ${label.extent[0]}`
    }
    return `${label.extent[0]} til ${label.extent[1]}`
  }

  /**
   * Render the calendar heatmap.
   *
   * @param data - The data to be rendered. Data are intended to be in
   *    the format of InternMap with two level of hierarchy. First is based
   *    on the month and the keys are supposed to be in format of YYYY-MM.
   *    Second level is based on the date. If data are missing months,
   *    the error is rendered instead. The end values format is supposed to be:
   *      - string - for ordinal scale.
   *      - number - for quantile and linear scale.
   *      - array - Currently, ordered array of strings, for ordinal scale.
   *          Latest element can be undefined. The resulting visualization
   *          will divide the box into triangles, each encoding information for
   *          one element in the array. Only first two elements are visualized.
   * @param inMonths - The months to be rendered. If not provided, the months
   *    are distilled from the data.
   * @param width - Intended width of the calendar heatmap. The core of
   *    the visualization is the single block of the day, which has to be
   *    square. The component tries to figure out how many blocks are needed to
   *    be rendered and then the final width is calculated. So if the provided
   *    height is too small, the resulting width could be smaller as well.
   * @param height - Intended height of the calendar heatmap. The core of
   *    the visualization is the single block of the day, which has to be
   *    square. The component tries to figure out how many blocks are needed
   *    to be rendered and then the final width is calculated. So if
   *    the provided width is too small, the resulting height could be smaller
   *    as well.
   * @param margin - Intended margin around the calendar heatmap. Influences
   *    the calculation of the core square per day dimensions.
   * @param ordinalScale - parameter to controll which scale to be used in
   *    visualization. Ordinal means categorical - each value is a different
   *    color and encoding. Parameter could be a simple 'true' value, in which
   *    case domain would be generated from the data. Or it could be an object
   *    with 'domain' parameter to specify the domain. Only one of scales will
   *    be used in the visualization (prioritized in the written order).
   * @param quantileScale - parameter to controll which scale to be used in
   *    visualization. Quantile means grouped continuous - each value is ordered
   *    to a specific group - quantile. Visualization would then copntinue as
   *    ordinal, where each group has it's color and encoding. Parameter could
   *    be just 'true', in which case domain would be generated from the data
   *    and at most five different 'quantile' groups would be used. Or it could
   *    an object with 'domain' parameter to specify the domain and 'categories'
   *    to specify the count of categories. Only one of scales will be used in
   *    the visualization (prioritized in the written order).
   * @param scaleThreshold - parameter to contrroll which scale to be used in
   *    visualization. Required input is an object with 'domain' parameter as a
   *    list of number thresholds. If the parameter is true the same processing
   *    is happening as for quantile and linear scales. Only one of scales will
   *    be used in the visualization (prioritized in the written order).
   * @param linearScale - parameter to controll which scale to be used in
   *    visualization. Linear means continuous, but linear growth encoded.
   *    In other words, increase in the value will be encoded as a consistent
   *    change of color. Not suitable if the data are sparse or with high level
   *    of variation. Parameter could be just 'true', in which case domain would
   *    be generated from the data. Or it could an object with 'domain'
   *    parameter to specify the domain. Only one of scales will be used in
   *    the visualization (prioritized in the written order).
   * @param monthsLabels - The labels for the months. Those are shown above each
   * month in heatmap visualization. If not provided, the months format from
   * the input data (YYYY-MM) will be used. Expected is an object with keys as
   * the months (YYYY-MM format) and values as the labels.
   * @param colorScheme - The color scheme to be used in the visualization.
   * Couple of predefined ones are available in common.js.
   * @param undefinedColor - The color to be used for missing data.
   * @param showLegend - If true, the legend will be shown for colors. 
   * @param legendLabel - The label for the legend.
   * @param showShapeLegend - If true, the legend will be shown for box/triangle.
   * @param shapeLegendLabel - The label for the shape legend.
   * @param shapeLegendLabels - The label of individual encoded shapes.
   * @param weekSelection - The week selection to be used in the visualization.
   *    Will create an empty box around weeks to be selected. Selection accepts
   *    values of "year" and "start"-week, "count" of weeks and color of the
   *    selection.
   * @param hidden - If true, the component will not be rendered.
   * @return {JSX.Element} - The rendered calendar heatmap.
   */
  render() {
    const {
      data,
      inMonths,
      width,
      height,
      margin = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      ordinalScale,
      quantileScale,
      linearScale,
      thresholdScale,
      monthsLabels,
      colorScheme = fullSchemeRdBu,
      undefinedColor = "#dddddd",
      showLegend = true,
      legendLabel = "Legend",
      showShapeLegend = false,
      shapeLegendLabel = 'Shape Legend',
      shapeLegendLabels = ['box', 'triangle'],
      shapeLegendColors = ['#dddddd', '#dddddd'],
      weekSelection = undefined,
      hidden = true,
    } = this.props;

    if (hidden) {
      return <div></div>;
    }

    const formatMonth = timeFormat("%Y-%m");
    const formatDay = timeFormat("%Y-%m-%d");
    const formatValue = format(".0f");

    let tmpMonths = inMonths ? inMonths : [...data.keys()].sort();
    if (Array.isArray(tmpMonths) && tmpMonths.length === 0) {
      return <div>Det er ikke nok data til å generere visualisering</div>;
    }

    const firstDay = timeMonth(new Date(tmpMonths[0]));
    const lastDay = timeMonth(new Date(tmpMonths[tmpMonths.length - 1]));
    lastDay.setDate(
      lastDay.getDate() + daysInMonth(tmpMonths[tmpMonths.length - 1]) - 1
    );
    if (isNaN(firstDay.getTime()) || isNaN(lastDay.getTime())) {
      return <div>Ugyldig dato. Kan ikke generere visualisering</div>;
    }

    const months = timeMonths(firstDay, lastDay).map((day) => formatMonth(day));
    if (months.length > 13) {
      return <div>Visualisering kan kun genereres over 12 måneder</div>;
    }

    const weeks = [
      ...timeDays(firstDay, lastDay)
        .reduce((weekSet, day) => {
          let mom = moment(day);
          weekSet.add(`${mom.isoWeekYear()}-${mom.isoWeek()}`);
          return weekSet;
        }, new Set())
        .values(),
    ];
    const weekSelectionStartIndex = weekSelection
      ? weeks.indexOf(`${weekSelection.year}-${weekSelection.start}`)
      : undefined;

    const monthsStartWeek = months.map((month) => {
      return timeMonday.count(new Date(months[0]), new Date(month));
    });

    const widthCellsCount = timeMonday.count(firstDay, lastDay) + 1;
    const cellSize = Math.min(
      (height - margin.top - margin.bottom - 21 - 21) / 7,
      (width - margin.left - margin.right - 2) / widthCellsCount
    );
    if (cellSize < 1) {
      return <div>Ingen visualisering å vise</div>;
    }
    const calcHeight = cellSize * 7 + margin.top + margin.bottom + 21 + 21;
    const calcWidth =
      cellSize * widthCellsCount + margin.left + margin.right + 2;

    const scale =
      typeof ordinalScale !== "undefined"
        ? {
            name: "ordinal",
            opts: ordinalScale,
            scaleFunc: scaleOrdinal(),
          }
        : typeof quantileScale !== "undefined"
        ? {
            name: "quantile",
            opts: quantileScale,
            scaleFunc: scaleQuantile(),
          }
        : typeof thresholdScale !== "undefined"
        ? {
            name: "threshold",
            opts: thresholdScale,
            scaleFunc: scaleThreshold(),
          }
        : {
            name: "linear",
            opts: linearScale,
            scaleFunc: scaleLinear(),
          };

    let domain = this.getDomain(scale.name, scale.opts, data);
    if (domain[0] == 9999 && domain[1] == -9999) {
      domain = [0, 0];
    }
    const colRange =
      colorScheme[
        scale.name === "ordinal"
          ? domain.length - 1
          : scale.name === "threshold"
          ? domain.length
          : typeof scale.opts.categories !== "undefined"
          ? scale.opts.categories
          : Math.min(domain[1] - domain[0], 5)
      ];
    const color = scale.scaleFunc.domain(domain).range(colRange);

    const shapeScale = scaleOrdinal({
      domain: shapeLegendLabels,
      range: [
        <rect
          fill={shapeLegendColors[0]}
          stroke="#ccc"
          width={cellSize}
          height={cellSize}
          x={0}
          y={0}
          rx={cellSize / 5}
          key={`rect-legend`}
        />,
        <path
          d={this.constructUpperTriangle(
            0, 0,
            cellSize, cellSize, cellSize / 5
          )}
          style={{
            fill: shapeLegendColors[1],
            stroke: "#ccc"
          }}
          key={`triangle-legend`}
        />,
        () => (
          <text key="e" fontSize="12" dy="1em" dx=".33em" fill="#e0a346">
            $
          </text>
        ),
      ],
    });

    return (
      <div
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <svg
          width={calcWidth}
          height={calcHeight}
          key={"calendar-heatmap"}
          className="calendar-heatmap"
        >
          <Group
            transform={`translate(${margin.left},${margin.top})`}
            key={`calendar-heatmap-group`}
          >
            {months.map((month, monthId) => (
              <Group
                transform={`translate(${
                  monthsStartWeek[monthId] * cellSize
                },0)`}
                key={`month-group-${month}`}
              >
                {/* Month label has to be aligned with the upper day boxes
                 *  In the most simple way, we just detect if the month starts
                 *  on Monday and adjust the offset.
                 */}
                <text
                  transform={`translate(${cellSize * (
                    moment(month, 'YYYY-MM').startOf('month').day() === 1 ? 2.5 : 3.0
                  )},${11})`}
                  fontFamily="sans-serif"
                  textAnchor="middle"
                  key={`month-label-${formatDay(month)}`}
                >
                  {typeof monthsLabels !== "undefined" ? monthsLabels[month] : month}
                </text>
                <Group
                  transform="translate(0,20)"
                  key={`month-days-group-${formatDay(month)}`}
                >
                  {range(0, daysInMonth(month)).map((dayIndex) => {
                    const day = new Date(month + "-" + (dayIndex + 1));
                    const dayString = formatDay(day);
                    let value = data.has(month)
                      ? data.get(month).get(dayString)
                      : undefined;
                    if (Array.isArray(value) && value.length === 1) {
                      value = value[0];
                    }
                    let offs = cellSize / 20;
                    return !Array.isArray(value) ? (
                      <rect
                        fill={
                          typeof value !== "undefined"
                            ? color(value)
                            : undefinedColor
                        }
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        x={getMonthWeek(day) * cellSize}
                        y={getDayOfWeek(day) * cellSize}
                        rx={cellSize / 5}
                        key={`day-rect-${dayString}`}
                      >
                        <title>
                          {dayString} :{" "}
                          {typeof value === "number"
                            ? formatValue(value)
                            : value}
                        </title>
                      </rect>)
                      : value.length === 2 ?
                      (<>
                        <path
                          d={this.constructUpperTriangle(
                            getMonthWeek(day) * cellSize,
                            getDayOfWeek(day) * cellSize,
                            cellSize, cellSize, cellSize / 5
                          )}
                          style={{
                            fill: typeof value[0] !== "undefined" ? color(value[0]) : undefinedColor,
                            stroke: "#ccc"
                          }}
                          key={`day-rect-${dayString}-0`}
                        ><title>
                            {dayString} :{" "}
                            {typeof value[0] === "number" ? formatValue(value[0]) : value[0]}
                          </title>
                        </path>
                        <path
                          d={this.constructBottomTriangle(
                            getMonthWeek(day) * cellSize,
                            getDayOfWeek(day) * cellSize,
                            cellSize, cellSize, cellSize / 5
                          )}
                          style={{
                            fill: typeof value[1] !== "undefined" ? color(value[1]) : undefinedColor,
                            stroke: "#ccc"
                          }}
                          key={`day-rect-${dayString}-1`}
                        ><title>
                            {dayString} :{" "}
                            {typeof value[1] === "number" ? formatValue(value[1]) : value[1]}
                          </title>
                        </path>
                      </>) : (
                        value.reduce((acc, v, i) => 
                          {
                            acc.push(<rect
                              fill={v ? color(v) : undefinedColor}
                              width={cellSize / 2 - 2 * offs}
                              height={cellSize / 2 - 2 * offs}
                              x={
                                (getMonthWeek(day) * cellSize)
                                + ((i % 2) * cellSize / 2)
                                + 2 * (1 - (i % 2)) * offs
                              }
                              y={
                                (getDayOfWeek(day) * cellSize)
                                + (Math.floor(i / 2) * cellSize / 2)
                                + 2 * (1 - Math.floor(i / 2)) * offs
                              }
                              key={`day-rect-${dayString}-${i}`}
                              rx={cellSize / 5}
                            />);
                            return acc;
                          },
                          [
                            <rect
                              fill={undefinedColor}
                              stroke="#aaa"
                              width={cellSize}
                              height={cellSize}
                              x={getMonthWeek(day) * cellSize}
                              y={getDayOfWeek(day) * cellSize}
                              rx={cellSize / 5}
                              key={`day-rect-${dayString}`}
                            >
                              <title>{dayString}</title>
                            </rect>
                          ]
                        )
                      );
                  })}
                </Group>
                <Group fill="none" stroke="#000" transform="translate(0,20)">
                  {
                    <path
                      d={this.getMonthPath(new Date(month), cellSize)}
                      strokeWidth={2}
                      strokeLinejoin="round"
                      key={`month-outlier-${formatDay(month)}`}
                    />
                  }
                </Group>
              </Group>
            ))}
          </Group>
          <Group
            transform={`translate(${margin.left},${
              cellSize * 7 + margin.top + 21
            })`}
            key={`calendar-heatmap-group-weeks`}
          >
            {weeks.map((week, weekId) => (
              <Group
                transform={`translate(${cellSize * weekId},0)`}
                key={`week-label-${weekId}`}
                width={cellSize}
                height={cellSize}
              >
                <rect
                  fillOpacity={0}
                  stroke="#ccc"
                  width={cellSize}
                  height={cellSize}
                  rx={0}
                  ry={0}
                  strokeDasharray={[2 * cellSize, cellSize]}
                ></rect>
                <text
                  fontFamily="sans-serif"
                  x={cellSize / 2}
                  y={cellSize / 2}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize={(cellSize * 2) / 3}
                >
                  {week.split("-")[1]}
                </text>
              </Group>
            ))}
          </Group>
          {weekSelection && (
            <Group
              transform={`translate(${margin.left},${margin.top + 21})`}
              key={`calendar-heatmap-group-week-selection`}
            >
              <rect
                fillOpacity={0.1}
                width={weekSelectionStartIndex * cellSize}
                height={7 * cellSize}
                strokeWidth={0}
                x={0}
                y={0}
              ></rect>
              <path
                fill="none"
                stroke={weekSelection.color}
                strokeWidth={4}
                d={`
                  m ${weekSelectionStartIndex * cellSize},0
                  v ${cellSize * 7}
                  h ${cellSize * weekSelection.count}
                  v -${cellSize * 7}
                  h -${cellSize * weekSelection.count - 1}
                `}
              />
              <rect
                fillOpacity={0.1}
                width={(weeks.length - weekSelectionStartIndex - weekSelection.count) * cellSize}
                height={7 * cellSize}
                strokeWidth={0}
                x={(weekSelectionStartIndex + weekSelection.count) * cellSize}
                y={0}
              ></rect>
            </Group>
          )}
        </svg>
        {showLegend ? 
        <div
          className="legend"
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom:
              margin !== "undefined" && margin.bottom !== "undefined"
                ? margin.bottom
                : 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="title">{legendLabel}</div>
          {scale.name === "ordinal" ? (
            <LegendOrdinal
              scale={color}
              labelFormat={(d) => {
                return typeof d === "number" ? formatValue(d) : d;
              }}
            >
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize}
                      height={cellSize}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendOrdinal>
          ) : scale.name === "quantile" ? (
            <LegendQuantile scale={color}>
              {(labels) =>
                labels.reduce((acc, label) => {
                    // Two stage processing:
                    // - first we round the extend limits to the closest integer
                    //   which is ceil for bottom and floor for top limits
                    let ceilBottom = Math.ceil(label.extent[0]);
                    let floorTop = Math.floor(label.extent[1]);
                    let ext = [
                      ceilBottom,
                      ... (ceilBottom < floorTop) ? [floorTop] : []
                    ]
                    // - second, we make sure, that the top limit does not
                    //   overlap with the previous bottom limit
                    if (acc.length > 0) {
                      let prev = acc[acc.length - 1].value;
                      if (prev[prev.length - 1] === ceilBottom) {
                        acc[acc.length - 1].value[prev.length - 1] -= 1
                      }
                    }
                    acc.push({
                      color: label.value,
                      value: ext
                    })
                    return acc
                  },
                  []
                ).map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize + 1}
                      height={cellSize + 1}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.color}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">
                      {label.value.length === 1 ? `${label.value[0]}` : `${label.value[0]} - ${label.value[1]}`}
                    </LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendQuantile>
          ) : scale.name === "threshold" ? (
            <LegendThreshold scale={color}>
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`threshold-legend-${i}`}>
                    <svg
                      width={cellSize}
                      height={cellSize}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">
                      {this.translateTreshold(label)}
                    </LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendThreshold>
          ): (
            <LegendLinear
              scale={color}
              labelFormat={(d) => {
                return typeof d === "number" ? formatValue(d) : d;
              }}
            >
              {(labels) =>
                labels.map((label, i) => (
                  <LegendItem key={`legend-${i}`}>
                    <svg
                      width={cellSize}
                      height={cellSize}
                      style={{ margin: "2px 0px 0px 15px" }}
                    >
                      <rect
                        fill={label.value}
                        stroke="#ccc"
                        width={cellSize}
                        height={cellSize}
                        rx={cellSize / 5}
                      />
                    </svg>
                    <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                ))
              }
            </LegendLinear>
          )}
          {showShapeLegend && <div style={{margin: "0 10px 0 20px"}} className="title">{shapeLegendLabel}</div>}
          {showShapeLegend && (
            <Legend scale={shapeScale}>
              {(labels) =>
                labels.map((label, i) => {
                  const shape = shapeScale(label.datum);
                  return (
                  <LegendItem key={`shape-legend-${i}`}>
                    <svg
                      width={cellSize + 1}
                      height={cellSize + 1}
                    >
                      {shape}
                    </svg>
                    <LegendLabel margin="0 4px">{label.text}</LegendLabel>
                  </LegendItem>
                )})
              }
            </Legend>
          )}
        </div>
        : <></>
        }
      </div>
    );
  }
}
