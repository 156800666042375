import React from "react";
import { Tabs, Tab } from "rendition";
import { DayInput } from "./day-input";

const TaskWeek = (params) => {
  const dayIds = Object.keys(params.days);
  const dayTabs = dayIds.map(day =>
    <Tab key={day} title={params.days[day]}>
      <DayInput register={params.register} unregister={params.unregister} day={day} task={params.task}
                errors={params.errors} positions={params.positions} traitNames={params.traitNames} watch={params.watch} patchTask={params.patchTask}
                doPatch={params.doPatch} setValue={params.setValue} employees={params.employees} department={params.department}/>
    </Tab>
  );

  return (
    <Tabs activeIndex={dayIds.indexOf(params.selectedDay)} onActive={i => params.setSelectedDay(dayIds[i])} >
      {dayTabs}
    </Tabs>
  )
};

export default TaskWeek;
