import React, {useContext, useState, useMemo} from "react";
import dynomiteContext from "./dynomite";
import Accordion from "./accordion";
import { Box, Flex, Txt } from "rendition";
import { Card } from "../lib/styled-components";
import CalendarHeatmap  from './vis-components/calendar-heatmap';
import { parseISO, addWeeks, subDays, formatISO, eachMonthOfInterval, getISODay, getISOWeek, getISOWeekYear } from 'date-fns';
import { rollupAndReduce, monthsShort, norwegianShiftNames } from "../lib/common";
import defaultTheme from "../themes/light-theme";
import EmployeeFilter from "./date_pattern/employee-filter";

export const TaskEmployees = ({
    employees,
    positions,
    task
}) => {
    const dynomite = useContext(dynomiteContext);
    const [searchFilters, setSearchFilters] = useState({
        employee: undefined,
        positions: []
    });

    /**
     * Adjust filtered employees (and their date patterns) based on the change
     * of filter parameters.
     * 
     * Filtering is cached for any non-filtering re-render.
     */
    const filteredEmployees = useMemo(
        () => employees
            .filter(e => 
                !searchFilters.employee
                || e.name.toLowerCase().includes(searchFilters.employee.toLowerCase())
            )
            .filter(e =>
                searchFilters.positions.length === 0
                || searchFilters.positions.includes(e.position)
            ),
        [searchFilters]
    )

    /**
     * Prepare input data for visualization (start/end dates, months labels)
     * - start/end date
     * - months list
     * - months labels
     */ 
    let startDate = task.config.startDate;
    let startDateIsoWeek = getISOWeek(parseISO(startDate, {representation: 'date'}));
    let startDateIsoYear = getISOWeekYear(parseISO(startDate, {representation: 'date'}));
    let endDate = formatISO(
        addWeeks(
            subDays(parseISO(startDate, {representation: 'date'}), 1),
            task.config.nrOfWeeks
        ),
        {representation: 'date'}
    );
    let inMonths = eachMonthOfInterval({
        start: parseISO(startDate, {representation: 'date'}),
        end: parseISO(endDate, {representation: 'date'})
    }).map((month) =>
        formatISO(month, {representation: 'date'})
            .split('-')
            .slice(0, 2)
            .join('-')
    );
    let monthsLabels = inMonths.reduce((a, v) => ({
        ...a,
        [v]: monthsShort[parseInt(v.split('-')[1]) - 1]
    }), {});

    /** Collect BP requirements for the whole schedule */
    let requirements = dynomite.dynomite.scheduleRequirements(JSON.stringify(task.config))
        .reduce((a, v) => ({...a, [v[0]]: v[1]}), {});

    /** Collect Position names for respective ids */
    let posNames = positions.reduce((a, v) => ({...a, [v.id]: v.name}), {});

    return <Card style={{
        borderTopLeftRadius: '0px',
        marginLeft: '0px',
        width: '1350px',
        padding: '25px'

    }}>
        <EmployeeFilter
            name={{
                value: searchFilters.employee,
                onChange: name => setSearchFilters({
                    ...searchFilters,
                    employee: name
                })
            }}
            positions={{
                value: positions,
                width: '300px',
                isMulti: true,
                onChange: positions => setSearchFilters({
                    ...searchFilters,
                    positions: positions
                }) 
            }}
        />
        <hr />
        <Accordion
            hidePanelDivider={false}
            keyFunc={(item) => item.id}
            items={
                filteredEmployees.map(empl => ({
                    key: `bp-employee-row-${empl.id}`,
                    startOpen: false,
                    scrollIntoView: false,
                    label: <Flex
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box style={{ "flex": "1 0 10%"}}>
                            <Txt style={{ "marginBottom": "1em" }}>{empl.name}</Txt>
                        </Box>
                        <Box style={{ "flex": "1 0 10%"}}>
                            <Txt style={{ "marginBottom": "1em" }}>{empl.vacancyRate + "%"}</Txt>
                        </Box>
                        <Box style={{ "flex": "1 0 10%"}}>
                            <Txt style={{ "marginBottom": "1em" }}>{posNames[empl.position]}</Txt>
                        </Box>
                    </Flex>,
                    panel: <CalendarHeatmap
                        data={rollupAndReduce(
                            dynomite.dynomite.employeeAvailability(
                                dynomite.dynomite.parseEmployee(JSON.stringify(empl)),
                                startDate,
                                endDate
                            ).map((data) => ({
                                Date: data[0],
                                Value: data[1]
                            })),
                            (day) => {
                                let dayReq = requirements[day[0].Date]
                                    .filter(req => empl.shiftTypeCodes.includes(req[0]))
                                    .map(req => req[1]);
                                return {
                                    Date: day[0].Date,
                                    Value: new Uint32Array(day[0].Value).reduce(
                                        (acc, v , i) => {
                                            // translate index i into day-segment
                                            let daySeg =
                                                i === 0 ? 'd' :
                                                i === 1 ? 'a' :
                                                i === 2 ? 'l' : 'n';
                                            
                                            // filter by day-segment availability
                                            // adjust value by no-work/maybe-work/work values
                                            let value = dayReq.includes(daySeg) ?
                                                v === 2 ? norwegianShiftNames(daySeg) :
                                                v === 1 ? norwegianShiftNames(daySeg) + '(kan jobbe)' : undefined
                                                : undefined;
                                            // remove sundays and saturdays if no-weekend required
                                            let date = parseISO(day[0].Date, {representation: 'date'});
                                            value = 
                                                getISODay(date) === 7 && v === 1 ? undefined :
                                                getISODay(date) === 6 && v === 1 ? undefined :
                                                value;
                                            acc.push(value);
                                            return acc;
                                        },
                                        []
                                    )
                                }
                            }
                        )}
                        inMonths={inMonths}
                        monthsLabels={monthsLabels}
                        width={1300}
                        height={220}
                        ordinalScale={{domain: [
                            'Dagvakt', 'Dagvakt(kan jobbe)',
                            'Kveldsvakt', 'Kveldsvakt(kan jobbe)',
                            'Langvakt/Mellomvakt', 'Langvakt/Mellomvakt(kan jobbe)',
                            'Nattevakt', 'Nattevakt(kan jobbe)'
                        ]}}
                        colorScheme={[[], [], [], [], [], [], [], [
                            '#1f78b4', '#b3cde3',
                            '#e31a1c', '#fbb4ae',
                            '#ff7f00', '#fed9a6',
                            '#33a02c', '#ccebc5'
                        ]]}
                        undefinedColor = "#ffffff"
                        legendLabel = ''
                        weekSelection={{
                            year: startDateIsoYear,
                            start: startDateIsoWeek,
                            count: task.config.nrOfWeeks,
                            color: defaultTheme.colors.primary.main
                        }}
                        hidden={false}
                    />
                })
            )}
        />
    </Card>
};
