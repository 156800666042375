/* eslint-disable no-unused-vars */
import React from "react";
import _ from 'lodash';
import { Input, Button } from "rendition";
import ErrorMessagePopover from "../error-message-popover";
import { InputWithValidation, NumberInput } from "../../lib/styled-components";
import {isTaskReadOnly, noDecimalRegex, shortenStr} from "../../lib/common";
import styled from "styled-components";

const CompetenceInputs = ({ traitNames, register, InputError, day, shift, shiftData, errors, highlightedInputs, deletePatch, task, checkLengthAndAdjust }) => {
  return Object.entries(traitNames).map(([traitId, traitName]) => {
    let traitReqs = shiftData.traitRequirements !== null && shiftData.traitRequirements !== undefined
        ? Object.entries(shiftData.traitRequirements).filter(([reqId, traitRequirement]) => traitRequirement.traitId === traitId)
        : {};
    let reqId = Object.keys(traitReqs).length > 0 ? traitReqs[0][0] : traitId;
    let traitRequirement = Object.keys(traitReqs).length > 0 ? traitReqs[0][1] : {traitId: traitId, operator: "min", value: 0};

    const isHighlighted = (reqId) => highlightedInputs.includes(`/${shift}/traitRequirements/${reqId}/value`);
    const path = `config.weeklyCoverDemands.${day}.${shift}.traitRequirements.${reqId}`;

    return <InputDivider key={`${path}.value`}>
      <p><label htmlFor={`${path}.value`}>{shortenStr(traitNames[traitRequirement.traitId], 20)}</label></p>
      <InputWithValidation width="100px">
        <NumberInput
            id={`${path}.value`}
            key={`${path}.value`}
            placeholder=""
            onChange={checkLengthAndAdjust(`${path}.value`, 999)}
            type="number"
            width="80px"
            defaultValue={traitRequirement.value}
            className={highlightedInputs.includes(`/${shift}/traitRequirements/${reqId}/value`) ? isTaskReadOnly(task) ? "input-highlight-read-only" : "input-highlight"  : ""}
            {...register(`${path}.value`, {
              required: true, valueAsNumber: true, min: 0, pattern: noDecimalRegex()
            })}
        />
        <Input
            id={`${path}.traitId`}
            key={`${path}.traitId`}
            type="hidden"
            width="80px"
            defaultValue={traitRequirement.traitId}
            {...register(`${path}.traitId`, {
              required: true
            })}
        />
        <Input
            id={`${path}.operator`}
            key={`${path}.operator`}
            type="hidden"
            width="80px"
            defaultValue={"min"}
            {...register(`${path}.operator`, {
              required: true
            })}
        />
        {ErrorMessagePopover(InputError(day, errors, shift)?.traitRequirements?.[reqId]?.value, 'required', 'Antall må ha en tallverdi')}
        {ErrorMessagePopover(InputError(day, errors, shift)?.traitRequirements?.[reqId]?.value, 'min', 'Antall kan ikke være negative tall')}
        {ErrorMessagePopover(InputError(day, errors, shift)?.traitRequirements?.[reqId]?.value, 'pattern', 'Desimaltall er ikke tillatt')}
        <div style={{ display: !isHighlighted(reqId) ? "none" : "", position: "absolute", float: "right", width: "120px", paddingTop: "2px", pointerEvents: "none" }}>
          <Button className="deletePatchButton" style={{ float: "right", pointerEvents: "all" }} ml="auto" plain onClick={() => deletePatch(`${day}.${shift}.traitRequirements.${reqId}.value`)}><img src="/assets/icons/roll.png" /></Button>
        </div>
      </InputWithValidation>
    </InputDivider>
  });
}

export default CompetenceInputs;

const InputDivider = styled.div`
  min-width: 190px;
  margin-bottom: 10px;
`;