export const shiftOptions = () => {
  var maxConsecutiveShiftsOptions = [0];
  for (let i = 2; i <= 9; i++) {
    maxConsecutiveShiftsOptions.push(i);
  }

  return maxConsecutiveShiftsOptions;
};

export const weekendShiftOptions = () => {
  let options = ["-"]
  for(let i = 3; i <= 9; i++) {
    options.push(i);
  }
  return options;
}

export const daySegmentMaxOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export const weekendFreqOptions = [0, 2, 3, 4, 5, 6]
export const longShiftOptions = [0, 2, 3, 4];
export const lateShiftOptions = [0, 2, 3, 4, 5];
export const weekendOptions = [0, 1, 2];
export const sundaysMaxSeqOptions = [1, 2, 3]
export const redDaysOptions = [1, 2, 3, 4, 5];
export const holidayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
